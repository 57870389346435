import React, { Component } from 'react'

export class PriceStyled extends Component{
	render(){
		let v = this.props.value
		v = v.replace('$','')
		v = v.split('.')
		let dollars = v[0],
			cents = v[1]

		return (
			<span className={'price price-styled' + (this.props.className ? ' ' + this.props.className : '')}>
				<span className='price-styled-currency'>$</span>
				<span className='price-styled-dollars'>{dollars}</span>
				<span className='price-styled-dot'>.</span>
				<span className='price-styled-cents'>{cents}</span>
			</span>
		)
	}
}
