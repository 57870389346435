import React, { useRef, useEffect, useState } from 'react'
import TrackVisibility from 'react-on-screen'
import { CONFIG } from 'velox-visualizer-tools'

import { VehicleImage }  from '../vehicle_image'
import { VehicleWheelImage } from '../vehicle_wheel'

import './vehicle.scss'

/**
 * Fixed Width Visualizer Vehicle Component
 */
 export function VisualizerVehicle({ 
	wheel, 
	vehicle, 
	className, 
	maxWidth, 
	responsive, 
	onClick, 
	set_ref, 
	onImageLoad, 
	onImageError,
	isVisible 
}){
	const [loaded, setLoaded] = useState(false)
	const [currentWidth, setCurrentWidth] = useState(maxWidth)

	const visualizerVehicleRef = useRef(null)
	const vehicleWrapperRef = useRef(null)

	set_ref = set_ref || this.set_ref 

	const vehicle_image = vehicle.current_color.image
	
	const wheelImage = wheel ? ((wheel.images && wheel.images.side ) ? wheel.images.side :  CONFIG.WHEELS_SIDE_BUCKET + "/" + wheel.image + ".png") : ""

	const style = responsive ? { maxWidth: maxWidth } : { width: maxWidth }

	function classNames(){
		let component_classes = ['visualizer-vehicle']
		component_classes.push(loaded ? 'is-loaded' : 'is-not-loaded')
		component_classes.push( isVisible ? 'is-in-view' : 'is-not-in-view' )
		if( className ){ component_classes.push(className) }
		return component_classes.join(' ')
	}

	function onWindowResize(){
		if( vehicleWrapperRef && vehicleWrapperRef.current ){
			setCurrentWidth(vehicleWrapperRef.current.offsetWidth)
		}
	}

	useEffect(() => {
		if( set_ref ){
			set_ref('visualizer_vehicle', visualizerVehicleRef )
			set_ref('vehicleWrapperRef', vehicleWrapperRef )
		}
		window.addEventListener('resize', onWindowResize)
		return () => {
			window.removeEventListener('resize', onWindowResize)
		}
	}, [visualizerVehicleRef, vehicleWrapperRef, set_ref])
	
	return (
		<div className={classNames()}
			ref={visualizerVehicleRef }
		>
			<div className={"vehicle-wrapper"}
				ref={vehicleWrapperRef}
				onClick={onClick}
				style={ style }
			>
				<VehicleImage
					src={vehicle_image}
					className="vehicle-image"
					alt="vehicle-image"
					onLoad={(x)=> {
						setLoaded(true)
						if( onImageLoad){ onImageLoad(x) }
					}}
					onError={(x) => {
						setLoaded(false)
						if( onImageError ){ onImageError(x)}
					}}
				/>
				{ !!wheel && (
					<VehicleWheelImage {...vehicle.image.wheel_positions.front}
						wheel_image={wheelImage}
						vehicle_width={currentWidth}
					/>)
				}
				{ !!wheel && (
					<VehicleWheelImage {...vehicle.image.wheel_positions.rear}
						wheel_image={wheelImage}
						vehicle_width={currentWidth}
					/>)
				}
			</div>
		</div>
	)
 }
 VisualizerVehicle.ref = {}
 VisualizerVehicle.set_ref = (label, e) => { this.ref[label] = e }

export function VisibleVisualizerVehicle(props){
	return (
		<TrackVisibility partialVisibility={true}>
			<VisualizerVehicle {...props} />
		</TrackVisibility>
	)
}

export default VisibleVisualizerVehicle
