export default {
    makes: [],
    models: [],
    years: [],
    sizes: [],
    results: {},
    selected: {
        make: null,
        model: null,
        year: null,
        size: null
    },
    is_touched: {
        makes: false,
        models: false,
        years: false,
        sizes: false
    },
    is_loading: {
        makes: false,
        models: false,
        years: false,
        sizes: false
    },
    is_open: {
        makes: false,
        models: false,
        years: false,
        sizes: false
    }
}