import React, { Component } from 'react'
import tinycolor from 'tinycolor2'

export class VehicleColorSwatch extends Component{
	shouldComponentUpdate(nextProps, nextState) {
		let { name, code, rgb1 } = this.props.item
		let { new_name, new_code, new_rgb1 } = nextProps.item
		return name !== new_name || code !== new_code || rgb1 !== new_rgb1
	}
	render(){
		let { name, code, rgb1 } = this.props.item
		let textValue = this.props.textValue
		let hex_color = '#' + rgb1,
			text_color = '#fff'

		if( !tinycolor.isReadable(text_color, hex_color) ){
			text_color = tinycolor(hex_color).darken(50).toString()
		}
		//
		return (
			<div className="color-swatch" style={{ backgroundColor: hex_color, color: text_color }}>
				<span className="color-name">{ name }</span>
			</div>
		)
	}
}

export default VehicleColorSwatch