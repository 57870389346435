export function get_images(settings) {
  let { num_images, url, size, format } = settings;
  let images = [];
  // get the image urls for each frame
  for (var i = 1; i <= num_images; i++) {
    if (url.indexOf("http") < 0) {
      url = `https://cdn.veloxwheels.com/product-images/360/${url}/`;
    }
    images.push(url + i + "-" + size + "." + format);
  }
  return images;
}

export function get_next_image_index(settings) {
  const {
    current_position,
    start_position,
    start_index,
    pixels_per_image,
    num_images,
    direction,
  } = settings;
  let pixels_per_image2 = pixels_per_image * (360 / num_images);
  // (1 image/#pixels_per_image)*( #px moved ) = #images
  let start_index0 = start_index - 1;
  let dx = current_position - start_position;
  let moved_indexes = Math.floor(dx / pixels_per_image) % num_images; // 26
  moved_indexes = moved_indexes * direction;
  if (moved_indexes < 0) {
    moved_indexes = num_images + moved_indexes;
  }

  // e.g. target index = (3 + 24) % 25 --> 2
  let new_index = (start_index0 + moved_indexes) % num_images;
  new_index = new_index + 1;

  console.log({
    start_index,
    start_index0,
    new_index,
    dx,
    start_position,
    current_position,
    pixels_per_image,
    pixels_per_image2,
    moved_indexes,
    num_images,
    direction,
  });

  if (dx && new_index !== start_index) {
    return new_index;
  } else {
    return start_index;
  }
}

export function get_next_image_index2(settings) {
  const {
    current_position,
    start_position,
    start_index,
    pixels_per_image,
    num_images,
    direction,
  } = settings;

  // (1 image/#pixels_per_image)*( #px moved ) = #images
  let dx = current_position - start_position;
  let dx_images = Math.floor(dx); // 26

  console.log({ dx, dx_images });
  // get relative movement only, so if frames=25, 50 --> 0, 26 --> 1, 28 --> 3... etc
  // positive dx = move right = spin counterclockwise (left)
  //dx_images = (dx_images % num_images) // 1
  //dx_images = (dx_images % num_images)*(-1) + 1

  // moving left --> turn clockwise
  let moved_indexes = (dx_images * direction) % num_images;

  // e.g. target index = (3 + 24) % 25 --> 2
  let new_index = (start_index + moved_indexes) % num_images;
  new_index = new_index + 1;

  console.log({
    start_index,
    new_index,
    dx,
    dx_images,
    start_position,
    current_position,
    pixels_per_image,
    moved_indexes,
    num_images,
    direction,
  });

  if (dx_images && new_index !== start_index) {
    return new_index;
  } else {
    return start_index;
  }
}
