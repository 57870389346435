import React, { Component } from 'react'

import { ResponsiveSidebar } from './responsive_sidebar'
import { Dropdown, EditPencil, Filter as FilterIcon } from '../../common'
import Filter from './filters'
import { VehicleColorOptions } from '../vehicle/vehicle_color_options'

import { currency } from 'velox-visualizer-tools'

import './sidebar.scss'

export const ChangeVehicleLink = (props) => (
  <a className="link-change-vehicle" onClick={ props.change_vehicle }>Change Vehicle</a>
)

export type ChangeVehicleButtonProps = {
	change_vehicle: React.MouseEventHandler<HTMLButtonElement>;
}

export function ChangeVehicleButton({ change_vehicle }: ChangeVehicleButtonProps){
	return (
		<button className="change-vehicle btn-change-vehicle btn sm blue" onClick={ change_vehicle } >
		  <EditPencil className="show-mobile" />
		  <span className="vxviz-sidebar-label">Change Vehicle</span>
		</button>
	)
}

export class VisualizerSidebar extends ResponsiveSidebar {
  constructor(){
    super(1000) // set the breakpoint for the ResponsiveSidebar component
  }
	className(){
		let component_classes = ['velox-visualizer-sidebar']
		if( this.state.menu_is_mobile ){ component_classes.push('is-mobile') }
		if( this.state.menu_is_collapsed ){ component_classes.push('is-menu-collapsed') }
		return component_classes.join(' ')
	}
    render() {
    	var { vehicle, results, wheels, wheels_filtered, wheels_collection, change_vehicle, handle_filter, select_color } = this.props
    	var { opened, width, height, menu_is_mobile, menu_is_collapsed } = this.state
      var filters_map = results.filters_map || {}

      return (
      	<aside className={ this.className() }>
      		<div className="selected-vehicle">
        		<small className="label">Results For</small>
	        	<h2 className="vehicle-name">
	            	{ vehicle.year } { vehicle.make } { vehicle.model }
        		</h2>
            <ChangeVehicleLink change_vehicle={ change_vehicle } />
	        </div>
          <div className="row">
            { vehicle.has_image() && (
              <VehicleColorOptions
                className='vehicle-color-options'
                colors={vehicle.image.colors}
                current_color={vehicle.current_color}
                onSelectColor={ select_color }
              />
            )}
            <div className="input-group selected-size">
              <label className="vxviz-sidebar-label"><span className="hide-mobile">Selected</span> Size</label>
              <Dropdown
                  data={vehicle.available_diameters}
                  defaultValue={vehicle.diameter}
                  value={vehicle.diameter}
                  className="vehicle-selection-dropdown"
                  onChange={ this.props.select_size }
              />
            </div>
            <div className="mobile-filter-button">
              <button className="btn" onClick={ this.show_on_mobile }>
                <FilterIcon />
              </button>
            </div>
          </div>
          { !!results.has_results && (
            <div className='result-filters'>
              <h3 className='title'>Filter By</h3>
              { !!wheels_collection && !!wheels_collection.length && <ul className="filters accordion accordion-inherit">
                <Filter
                  title="Width"
                  field="width"
                  valueType="NUMBER"
                  initial_open={true}
                  collection={ wheels_collection }
                  onChange={ handle_filter }
                  appliedFilters={ filters_map.width }
                />
                <Filter
                  title="Series"
                  field="series"
                  initial_open={true}
                  collection={ wheels_collection }
                  onChange={ handle_filter }
                  appliedFilters={ filters_map.series }
                />
                <Filter
                  title="Finish"
                  field="finish_description"
                  collection={ wheels_collection }
                  onChange={ handle_filter }
                  appliedFilters={ filters_map.finish_description }
                />
                <Filter
                  title="Price"
                  field="current_price"
                  textValue={( x => currency(x) )}
                  valueType="NUMBER"
                  collection={ wheels_collection }
                  onChange={ handle_filter }
                  appliedFilters={ filters_map.current_price }
                />
              </ul>}
            </div>
          )}
      	</aside>
      )
    }
}
