import $ from 'jquery'
import React from 'react'
import ReactDOM from 'react-dom'
import { VehicleSelectionForm } from './vehicle_selection_form'
import { OrderHistoryTable, initialize_inline_editable } from '../components/order_history_table'
import { InlineEditable } from '../components/InlineEditable'
import { setup_ajax_csrf } from '../utils'
import './dashboard.scss'

function slugify(x){
	x = String(x).trim().toLowerCase()
	x = x.replace(' ', '-')
	return x
}

$(document).ready(function(){
	
	VehicleSelectionForm.initialize('grid')
	OrderHistoryTable.initialize()
	setup_ajax_csrf()

	$('.tracking-number').each(function(){
		let $el = $(this),
			$tracking_details = $el.parents('.tracking-details'),
			$icon = $el.find('.icon-circle-triangle'),
			$tracking_activity = $tracking_details.find('.tracking-activity-event-list'),
			$tracking_latest_activity = $tracking_details.find('.tracking-latest-activity')
		$icon.click(function(){
			let class_name = 'is-details-open',
				has_class = $tracking_details.hasClass(class_name)

			if( has_class ){
				$tracking_details.removeClass(class_name)
			}else{
				$tracking_details.addClass(class_name)
			}
		})
	})

	initialize_inline_editable()
	
	console.log('Dashboard initialized')
})
