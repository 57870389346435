import Promise from "bluebird"
import _ from "lodash"

import { history } from '../../../history'

import ActionCreators from "../action_creators"
import { INITIALIZE_API, LOAD_RESULTS, RESTORE_VEHICLE_SELECTION, VEHICLE_SELECT_LOAD_OPTIONS, VEHICLE_SELECT_VALUE } from "../actions"
import VeloxAPI from '../api'

import { lower, proper, plural, VEHICLE_SELECTION_DROPDOWNS, save_vehicle_cookie } from "velox-visualizer-tools"

export function action_load_options(field, options){
	return {
		type: VEHICLE_SELECT_LOAD_OPTIONS,
		data: { field, options }
	}
}

export function action_select_value(field, value){
	return {
		type: VEHICLE_SELECT_VALUE,
		data: { field, value }
	}
}

export function initialize(config={}){
	return function(dispatch, getState){
		return new Promise((resolve, reject) => {
			var API = getState().API
			API.Vehicles.Get.Makes()
				.then( makes => {
					dispatch(action_load_options('make', makes))
					resolve(makes)
				})
				.catch( e => {
					console.error(e)
					reject(e)
				})
		})
	}
}

export function select(field, value){
	return function(dispatch, getState){
		dispatch(action_select_value(field, value))
		const API = getState().API

		return new Promise((resolve, reject) => {
			var selected = _.omitBy(getState().form_select.selected, v => !v)
			let next_field = VEHICLE_SELECTION_DROPDOWNS.after(field)
			var get_next = proper(plural(next_field))
			API.Vehicles.Get[ get_next ](selected)
				.then( results => {
					if( next_field === 'wheels' ){
						dispatch(ActionCreators[LOAD_RESULTS](results))
						save_vehicle_cookie(selected)
					}else{
						dispatch(action_load_options(next_field, results))
					}
					resolve(results)
				})
				.catch( e => reject(e) )
		})
	}
}
