import React, { Component } from 'react'
import { CONFIG } from "velox-visualizer-tools"
const {  IMG_DIR_BASE } = CONFIG

export const Loading = (props) => (
	<div className="icon icon-loading loading">
		<img src={ IMG_DIR_BASE  + '/ui/loader.gif' } alt="loading" />
		<span>Loading....</span>
	</div>
)

export const LoadingSmall = (props) => (
	 <img
	 	className="icon icon-loading loader-small"
	 	src={ IMG_DIR_BASE + '/ui/loader.svg' }
	 	alt="loading"
	/>
)

export const LoadingDots = (props) => (
	<img
		src={ IMG_DIR_BASE + '/ui/threedots.svg' }
		className="icon icon-loading loader loader-threedots"
		alt="loading"
	/>
)

export const LoadingSpin = (props) => (
	<svg viewBox="0 0 38 38" className="icon icon-loading icon-loading-spin">
	    <g fill="none" fillRule="evenodd">
	        <g transform="translate(1 1)" strokeWidth="4">
	            <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
	            <path d="M36 18c0-9.94-8.06-18-18-18">
	                <animateTransform
	                    attributeName="transform"
	                    type="rotate"
	                    from="0 18 18"
	                    to="360 18 18"
	                    dur="0.5s"
	                    repeatCount="indefinite"/>
	            </path>
	        </g>
	    </g>
	</svg>
)

export const LoadingGrid = (props) => (
	<svg width="105" height="105" viewBox="0 0 105 105" className="icon icon-loading icon-loading-grid">
	    <circle cx="12.5" cy="12.5" r="12.5">
	        <animate attributeName="fill-opacity"
	         begin="0s" dur="0.5s"
	         values="1;.2;1" calcMode="linear"
	         repeatCount="indefinite" />
	    </circle>
	    <circle cx="12.5" cy="52.5" r="12.5" fillOpacity=".5">
	        <animate attributeName="fill-opacity"
	         begin="100ms" dur="0.5s"
	         values="1;.2;1" calcMode="linear"
	         repeatCount="indefinite" />
	    </circle>
	    <circle cx="52.5" cy="12.5" r="12.5">
	        <animate attributeName="fill-opacity"
	         begin="300ms" dur="0.5s"
	         values="1;.2;1" calcMode="linear"
	         repeatCount="indefinite" />
	    </circle>
	    <circle cx="52.5" cy="52.5" r="12.5">
	        <animate attributeName="fill-opacity"
	         begin="600ms" dur="0.5s"
	         values="1;.2;1" calcMode="linear"
	         repeatCount="indefinite" />
	    </circle>
	    <circle cx="92.5" cy="12.5" r="12.5">
	        <animate attributeName="fill-opacity"
	         begin="800ms" dur="0.5s"
	         values="1;.2;1" calcMode="linear"
	         repeatCount="indefinite" />
	    </circle>
	    <circle cx="92.5" cy="52.5" r="12.5">
	        <animate attributeName="fill-opacity"
	         begin="400ms" dur="0.5s"
	         values="1;.2;1" calcMode="linear"
	         repeatCount="indefinite" />
	    </circle>
	    <circle cx="12.5" cy="92.5" r="12.5">
	        <animate attributeName="fill-opacity"
	         begin="700ms" dur="0.5s"
	         values="1;.2;1" calcMode="linear"
	         repeatCount="indefinite" />
	    </circle>
	    <circle cx="52.5" cy="92.5" r="12.5">
	        <animate attributeName="fill-opacity"
	         begin="500ms" dur="0.5s"
	         values="1;.2;1" calcMode="linear"
	         repeatCount="indefinite" />
	    </circle>
	    <circle cx="92.5" cy="92.5" r="12.5">
	        <animate attributeName="fill-opacity"
	         begin="200ms" dur="0.5s"
	         values="1;.2;1" calcMode="linear"
	         repeatCount="indefinite" />
	    </circle>
	</svg>
)
export class RootLoading extends Component{
	componentDidMount(){
		if(document.body.className.length > 0 && document.body.className[document.body.className-1] !== ' '){
			document.body.className += ' '
		}
		document.body.className += 'is-root-loading'
	}
	componentWillUnmount() {
		document.body.className = document.body.className.replace(/\s*is-root-loading/,'')
	}
	render(){
		return (
			<div className="loading">
				<LoadingSmall />
				<small className="loading-text">Initializing...</small>
			</div>
		)
	}
}

export default Loading
