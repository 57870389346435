export const VEHICLES_BUCKET = 'https://cdn.veloxwheels.com'
export const BRAND_BUCKET = 'https://cdn.veloxwheel.com/brand'
export const PRODUCTS_BUCKET = 'https://cdn.veloxwheel.com/products/png'
export const WHEELS_SIDE_BUCKET = 'https://cdn.veloxwheels.com/products/side'
export const IMG_DIR_BASE = 'https://cdn.veloxwheels.com'
export const UI_IMG_BUCKET = IMG_DIR_BASE + '/ui'

export const SORT_OPTIONS = [
	{ 	'name': 'Series',	'field': 'series'	},
	{ 	'name': 'Name',		'field': 'model'	},
	{ 	'name': 'Price',	'field': 'current_price'	},
]
export const SORT_FUNCTIONS = {
	'default': x => {
		let is_priority = x.brand.includes('6061')
		let p = is_priority ? 1 : 2
		return p + x.model + x.finish
	}
}
SORT_OPTIONS.forEach( o => {
	SORT_FUNCTIONS[o.field] = x => x.field
})

export const SORT_DIRECTIONS = [
	{ 	'name': 'Ascending',	'value': 'asc'			},
	{ 	'name': 'Descending',	'value': 'desc'			}
]
export const COOKIE_SELECTED_VEHICLE = 'VELOX_SELECTED_VEHICLE'
