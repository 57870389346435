import React, { Component, useRef } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom";
import { WheelImage } from "../../../common/images"
import { Caret, SliderArrow } from "../../../common/icons"
import { ErrorBoundary } from '../../../common/error_boundary'
import { css_class, currency } from "velox-visualizer-tools"

import { WheelResultsToolbar } from "../toolbar"
import './results_slider.scss'
const Slider = require("react-slick").default

export interface Wheel{
	model: string;
	finish: string;
	current_price: string;
	images: {
		small: string;
		large: string;
		side: string;
	};
}

export type WheelResultsSliderProps = {
	wheels: Wheel[];
	current_wheel?: Wheel;
	select_wheel: Function;
}

export function WheelResultsSlider({ wheels, current_wheel, select_wheel }: WheelResultsSliderProps){

	const prevArrow = useRef(null)
	const nextArrow = useRef(null)
	const sliderRef = useRef(null)

	function onClick(slide: Wheel, current_wheel?: Wheel){
		return function(e){
			if( !current_wheel || ( current_wheel.model !== slide.model ) || ( current_wheel.finish !== slide.finish )){
				select_wheel(slide)
			}
		}
	}

	function generateSlides(slide: Wheel, index: number){
		let is_selected = current_wheel && ((current_wheel.model + current_wheel.finish) === (slide.model + slide.finish))
		return (
			<div
				className={'slide slide-' + index + ( is_selected ? ' is-selected' : '')}
				onClick={ onClick(slide, current_wheel) }
				key={'slide-' + index}
			>
				<WheelImage
					url={slide.images.small}
					className={( is_selected ? ' is-selected' : '')}
					type="small"
				/>
				<aside className="slide-details">
					<span className="wheel-name slide-details-item">{ slide.model }</span>
					<span className="wheel-finish slide-details-item">{ slide.finish }</span>
					<span className="wheel-price slide-details-item">{ currency(slide.current_price) }</span>
				</aside>
			</div>
		)
	}

	const sliderSettings = {
		dots: false,
		infinite: false,
		slidesToShow: 7,
		slidesToScroll: 5,
		arrows: true,
		prevArrow: <SliderArrow direction="left" ref={prevArrow}  className="slider-arrow-prev" />,
		nextArrow: <SliderArrow direction="right" ref={nextArrow} className="slider-arrow-next" />,
		autoplay: false,
		responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 5,
				slidesToScroll: 3,
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 3
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 3
			  }
			}
		]
	}

	if( wheels && wheels.length ){
		return (
			<div className="wheel-slider">
				<Slider ref={ sliderRef } {...sliderSettings}>
					{ wheels.map( generateSlides )}
				</Slider>
			</div>
		)
	}else{
		return (
			<div></div>
		)
	}
}

export default WheelResultsSlider
