import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"

import { ImageIfExists } from "../../../common/image_if_exists"

import './vehicle_wheel.scss'

export class VehicleWheelImage extends React.Component{
	/*
	static propTypes = {
		x: PropTypes.number.isRequired,
		y: PropTypes.number.isRequired,
		radius: PropTypes.number.isRequired,
		wheel_image: PropTypes.string.isRequired,
		vehicle_width: PropTypes.number.isRequired
	}
	*/
	state = {
		exists: true,
		loaded: false
	}
	shouldComponentUpdate(next_props, next_state) {
		let a = this.props, b = next_props
		const prop_changed = (prop) => _.get(a, prop) !== _.get(b, prop)

		let props_changed = prop_changed('x') || prop_changed('y') || prop_changed('radius') || prop_changed('wheel_image') || prop_changed('vehicle_width')
		let state_changed = next_state.exists !== this.state.exists || next_state.loaded !== this.state.loaded
		return props_changed || state_changed
	}
	onImageLoad = result => {
		this.setState(_.pick(result, ['exists', 'loaded']))
	}
	get_wheel_styles = () => {
		let { x, y, radius, wheel_image, vehicle_width } = this.props
		let width = radius*2,
			scale_ratio = (vehicle_width / 640)

		let new_width = scale_ratio * (width + 3),
			new_height = new_width

		return {
			top: 	scale_ratio * (y - 1),
			left: 	scale_ratio * (x - 1),
			width: new_width,
			height: new_height,
			boxShadow: `0 0 ${new_width*0.1}px ${new_width*0.09}px rgba(0,0,0,0.95)`
		}
	}
	className(){
		let component_classes = ['vehicle-wheel']
		component_classes.push( this.state.loaded ? 'is-not-loading' :'is-loading' )
		component_classes.push( this.state.exists ? 'is-found' : 'is-not-found' )
		if( this.props.className ){ component_classes.push(this.props.className) }
		return component_classes.join(' ')
	}
	render(){
		let wheel_styles = this.get_wheel_styles()
		let { loaded, exists } = this.state
		const { onImageLoad } = this
		return (
			<div className={this.className()} style={ wheel_styles }>
				{!!(loaded && exists) && (<div className='brakecalipers' />)}
				<ImageIfExists
					src={ this.props.wheel_image }
					alt={ 'visualizer wheel' }
					style={{ width: '100%', height: '100%' }}
					onLoad={ onImageLoad }
				/>
			</div>
		)
	}
}

export default VehicleWheelImage
