import React from 'react'
import $ from 'jquery'
import { VehicleSelectionForm as VisualizerVehicleSelectionForm } from '../components/veloxvisualizer'
import './vehicle_selection_form.scss'
export const VehicleSelectionForm = (function(){

	var DEFAULT_OPTIONS  = {
		preload: 'focus',
		openOnFocus: 'true',
		searchField: ['value','text'],
		create: false,
		render: {
			option: function(item, escape){
				return `<option value="${item.value}">${item.text}</option>`
			}
		}
	}

	var SELECTED = { make: null, model: null, year: null, size: null }

	function load_data_into_dropdown(el, data){
		var dropdown = el[0].selectize
		dropdown.clearOptions()
		dropdown.renderCache = {}
		dropdown.addOption(data)
		dropdown.refreshOptions()
		dropdown.enable()
		dropdown.focus()
	}

	function clear_dropdown($el, data, initial=null){
		var dropdown = $el[0].selectize
		dropdown.disable()
		dropdown.clearOptions()
	}

	function load_remote_options($el, url){
		$.get(url, function(data){
			data = _.map(data, (x, i) => ({ value: x, text: x }))
			load_data_into_dropdown($el, data)
		})
	}

	function initialize_vehicle_selection_form(){
		var $selectmake = $('#select-make'),
			$selectmodel = $('#select-model'),
			$selectyear = $('#select-year'),
			$selectsize = $('#select-size'),
			$submit = $('#vehicle-selection-form button[type="submit"]')

		if( $('#vehicle-selection-form').length ){
			$selectmake.selectize(_.assign({}, DEFAULT_OPTIONS, { placeholder: 'Select Make' }))
			$selectmodel.selectize(_.assign({}, DEFAULT_OPTIONS, { placeholder: 'Select Model' }))
			$selectyear.selectize(_.assign({}, DEFAULT_OPTIONS, { placeholder: 'Select Year' }))
			$selectsize.selectize(_.assign({}, DEFAULT_OPTIONS, { placeholder: 'Select Size' }))

			$selectmake.change(function(){
				var value = $(this).find(':selected').val()
				if( value && value.length ){
					SELECTED.make = value
					load_remote_options($selectmodel, `/api/vehicles/models?make=${SELECTED.make}`)
					clear_dropdown($selectyear); SELECTED.year = null;
					clear_dropdown($selectsize); SELECTED.size = null;
				}
			})
			$selectmodel.change(function(){
				var value = $(this).find(':selected').val()
				if( value && value.length ){
					SELECTED.model = value
					load_remote_options($selectyear, `/api/vehicles/years?make=${SELECTED.make}&model=${SELECTED.model}`)
					clear_dropdown($selectsize); SELECTED.size = null;
				}
			})
			$selectyear.change(function(){
				var value = $(this).find(':selected').val()
				if( value && value.length ){
					SELECTED.year = value
					load_remote_options($selectsize, `/api/vehicles/sizes?make=${SELECTED.make}&model=${SELECTED.model}&year=${SELECTED.year}`)
					clear_dropdown($selectsize); SELECTED.size = null;
				}
			})
			$submit.click(function(){

			})
		}
	}

	return {
		selected: SELECTED,
		initialize: function(layout='column'){
			VisualizerVehicleSelectionForm.initialize(
				'#vehicle-selection-form-wrapper',
				{
					title: false,
					layout: layout,
					button_text: 'Submit',
					onSubmit: function(results){
						let { make, model, year, diameter } = results.vehicle
						window.location = `/orders/select-wheel/?make=${make}&model=${model}&year=${year}&diameter=${diameter}`
					}
				}
			)
			//return initialize_vehicle_selection_form()
		}
	}

})()
