import React, { Component } from 'react'

export const NoWheelResults = (props) => {
	if( props.vehicle ){
		return (
			<div className="wheel-results">
				<section className="no-results-message">
					<h2 className="title-2">{ props.vehicle.make } { props.vehicle.model } { props.vehicle.year } <span className="grey">(Diameter: { props.vehicle.diameter })</span></h2>
					<h1 className="title">Sorry! No wheel results were found for your selected vehicle and size.</h1>
					<p>We might be out of stock or this vehicle application may not be currently supported. Please contact our wheel specialists for more information or select another size or vehicle.</p>
				</section>
			</div>
		)
	}else{
		return (
			<div className='wheel-results'>
				<section className='no-results-message'>
					<h1 className='title'>No vehicle selected</h1>
					<p>Please select a vehicle to get started.</p>
				</section>
			</div>
		)
	}
}