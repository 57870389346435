import React, { Component, MouseEventHandler, ReactNode } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { Remove, Cancel } from "./icons"
import { upper } from "velox-visualizer-tools"

export interface TagProps{
	onRemove?: MouseEventHandler;
	children: ReactNode;
}
export function Tag({ onRemove, children }: TagProps){
	return (
		<li className="tag" key={"tag-"+ children }>
			{ !!onRemove && <Cancel onClick={ onRemove } /> }
			{ children }
		</li>
	)
}

export interface FilterTagProps{
	filter: any;
	remove: MouseEventHandler;
}

export function FilterTag({filter, remove }: FilterTagProps){
	const FILTER_MAP = {
		"CURRENT_PRICE": "PRICE",
		"FINISH_DESCRIPTION": "FINISH"
	}
	const filter_field = Object.keys(filter)[0]

	let pretty_filter_field = FILTER_MAP[upper(filter_field)]
	pretty_filter_field = pretty_filter_field ? pretty_filter_field : filter_field

	return (
		<Tag onRemove={ remove }>
			<span className="filter-field">{ pretty_filter_field }</span>
			<span className="filter-value">{ filter[filter_field] }</span>
		</Tag>
	)
}