// Constant variables for action names

export const LOAD_RESULTS = 'LOAD_RESULTS'

export const SELECT_COLOR = 'SELECT_COLOR'
export const SELECT_SIZE = 'SELECT_SIZE'

export const RESULTS_IS_LOADING = 'RESULTS_IS_LOADING'
export const RESULTS_FILTER_ADD = 'RESULTS_FILTER_ADD'
export const RESULTS_FILTER_REMOVE =  'RESULTS_FILTER_REMOVE'
export const RESULTS_FILTER_RESET = 'RESULTS_FILTER_RESET'
export const RESULTS_FILTER_TOGGLE = 'RESULTS_FILTER_TOGGLE'
export const RESULTS_SORT = 'RESULTS_SORT'

export const MODAL_TOGGLE = 'MODAL_TOGGLE'

export const RESET_WHEEL = 'RESET_WHEEL'
export const SELECT_WHEEL = 'SELECT_WHEEL'
export const SELECT_WHEEL_OPTION = 'SELECT_WHEEL_OPTION'
export const SET_QTY = 'SET_QTY'

export const INITIALIZE_API = 'INITIALIZE_API'
export const APP_CONFIGURE = 'APP_CONFIGURE'
export const APP_IS_INITIALIZED = 'APP_IS_INITIALIZED'
export const APP_IS_LOADING = 'APP_IS_LOADING'
export const APP_HAS_RESULTS = 'APP_HAS_RESULTS'

export const GET_SERIES_INFO = 'GET_SERIES_INFO'

export const SHOW_DETAILS = 'SHOW_DETAILS'
export const SHOW_COST = 'SHOW_COST'
export const HIDE_COST = 'HIDE_COST'
export const TOGGLE_COST = 'TOGGLE_COST'

export const RESTORE_VEHICLE_SELECTION = 'RESTORE_VEHICLE_SELECTION'
export const VEHICLE_SELECT_RESET = 'VEHICLE_SELECT_RESET'
export const VEHICLE_SELECT_INITIALIZE = 'VEHICLE_SELECT_INITIALIZE'
export const VEHICLE_SELECT_IS_LOADING = 'VEHICLE_SELECT_IS_LOADING'
export const VEHICLE_SELECT_VALUE = 'VEHICLE_SELECT_VALUE'
export const VEHICLE_SELECT_LOAD_OPTIONS = 'VEHICLE_SELECT_LOAD_OPTIONS'

export const MODAL_OPEN = 'MODAL_OPEN'
export const MODAL_SHOW = 'MODAL_SHOW'
export const MODAL_HIDE = 'MODAL_HIDE'