import React from 'react'
import PropTypes from 'prop-types'

import { WheelResultsToolbar } from '../toolbar'
import { WheelResultsSlider } from '../slider'
import { NoWheelResults } from './result_errors'
import { WheelResultGrid } from './wheel_result_grid'

import actions from '../../../action_creators'

import './wheel_results.css'

export function WheelResults(props){
	const { results, cart, wheels, viewed, vehicle, sort_options, sort_directions, onCheckout, showToggleCost, showCost, setCostVisibility, dispatch } = props

	function className(){
		let component_classes = ['wheel-results']
		let has_results = results && results.has_results
		component_classes.push( has_results ? 'has-no-results' : 'has-results')
		if( className ){ component_classes.push( className) }
		return component_classes.join(' ')
	}

	const select_wheel = (wheel) => {
		dispatch( actions.SELECT_WHEEL(wheel) )
	}

	if( results && results.has_results && wheels ){
		return (
			<div className={ className() }>
				<WheelResultsToolbar
					wheels={wheels}
					wheels_all={results.wheels}
					filters={results.filters}
					vehicle={vehicle}
					sort_options={sort_options}
					sort_directions={sort_directions}
					dispatch={dispatch}
					showCost={showCost}
					setCostVisibility={setCostVisibility}
				/>
				{ !!results.show_details && (
					<WheelResultsSlider
						wheels={ wheels }
						current_wheel={ cart.wheel }
						wheels_all={ results.wheels }
						vehicle={ vehicle }
						filters={ results.filters }
						select_wheel={ select_wheel }
						sort_options={ sort_options }
						sort_directions={ sort_directions }
						showCost={showCost}
					/>
				)}
				<WheelResultGrid
					wheels={ wheels }
					select_wheel={ select_wheel }
					onCheckout={ onCheckout }
					viewed={ viewed }
				/>
			</div>
		)
	}else{
		return (<NoWheelResults vehicle={vehicle} />)
	}
}
export default WheelResults
