import React, { Component, useContext, useState, useEffect, useRef, ReactChildren } from 'react'
import ReactDOM from 'react-dom'


import Components, { PriceStyled, ShowCostButton } from '../../common'
import { proper, currency } from 'velox-visualizer-tools'
import { VeloxVisualizerContext } from '../../context'

const { AddToCart, SocialShareButtons } = Components.Buttons
const { Cancel, ShippingCar, HardwareParts, CheckmarkCircle, FullScreenIcon, ExitFullScreenIcon } = Components.Icons
const { SeriesLogo, WheelImage } = Components.Images
const Image360 = Components.Image360

export interface PropsWithChildren{
	children: string;
}

export const Name = ({ children }: PropsWithChildren) =>  (<h3 className="product-name">{ children }</h3>)
export const Finish = ({ children }: PropsWithChildren) => (
	<div className={'wheel-finish' + ((children && children.length > 16) ? ' long' : '') }>
		{ proper(children) }
	</div>
)
export const Series = ({ children }: PropsWithChildren) => (
	<div className="product-series">
		<SeriesLogo series={children} />
		<span className="series-text">{ proper(children) } Series</span>
	</div>
)
export const CustomerItemNumber = ({ children }: PropsWithChildren) => {
	const { terms } = useContext(VeloxVisualizerContext)

	return (
		<div className="customer-item-number">
			<label className="label">{terms.partner_sku} #:</label>
			<span className="value">{ children }</span>
		</div>
	)
}
export const VeloxItemNumber = ({ children }: PropsWithChildren) => (
	<div className="velox-item-number">
		<label className="label">Velox PN #:</label>
		<span className="value">{ children }</span>
	</div>
)
export const Availability = () => (
	<div className="product-availability"></div>
)
export const Availability2 = ({ is_available }: { is_available?: boolean }) => (
	<div className="product-availability">
		{ !!is_available && <span className="is-available"><CheckmarkCircle />In Stock</span> }
		{ !is_available && <span className="is-not-available"></span> }
	</div>
)
export const Shipping = () => (
	<div className='product-shipping-details'>
		<ShippingCar /><span className="text">Shipping and Handling Included</span>
	</div>
)
export const InstallationHardware = () => (
	<div className="wheel-installation-hardware-info">
		<HardwareParts /><span className="text">Installation Hardware Kit Included</span>
	</div>
)
export const Description = ({ content }: { content: string }) => (
	<div className="wheel-description" dangerouslySetInnerHTML={{__html: content }} />
)

export type PricesProps = {
	original_price: string;
	current_price: string;
	priceLabel?: string;
	showCost: boolean;
	setCostVisibility: (showCost: boolean) => void;
	cost?: string|number;
	currentCost?: string|number;
}

export const Prices = (props: PricesProps) => {

	const { terms, showCostButton } = useContext(VeloxVisualizerContext)
	const isBsro = terms.partner == 'Bsro'

	const original_price = currency(props.original_price)
	const current_price = currency(props.current_price)
	const savings = (((Number(props.original_price) - Number(props.current_price))/Number(props.original_price) )*100).toFixed(0)
	const priceLabel = props.priceLabel || (isBsro ? 'Retail Price' : 'Price')
	const showCost = props.showCost
	const cost = currency(props.cost) || null
	const currentCost = currency(props.currentCost) || null
	const isDiscount = original_price !== current_price
	
	const setCostVisibility = props.setCostVisibility

	const RetailPrice = () => ( !isBsro && (props.original_price !== props.current_price) ? (
		<div className="wheel-price unit-price is-discount">
			<div className="discount-price">
				<label className="label">{priceLabel}</label>
				<div className="value">
					<PriceStyled className="current-price" value={ current_price } />
					<aside className="original-price"><PriceStyled value={ original_price } /></aside>
					<small>each</small>
				</div>
			</div>
		</div>
	): (
		<div className="wheel-price unit-price current-price is-not-discount">
			<label className="label">{priceLabel}</label>
			<div className="value">
				<PriceStyled value={ current_price } />
				<small>each</small>
			</div>
		</div>
	))

	const StoreCost = () => ( showCost ? (
		<div className={"wheel-price unit-price store-cost " + (isDiscount ? 'is-discount' : 'is-not-discount')}>
			<label className="label">{terms.partner} Cost</label>
			{ isDiscount ? (
				<div className="value">
					<PriceStyled className="current-price" value={ currentCost } />
					<aside className="original-price"><PriceStyled value={ cost } /></aside>
					<small>each</small>
				</div>
			): (
				<div className="value">
					<PriceStyled value={ currentCost } />
					<small>each</small>
				</div>
			 )}
		</div>
	): (
		<div></div>
	))

	return (
		<div className="wheel-prices">
			<RetailPrice />
			{ !!showCost && (<StoreCost />) }
			{ !!showCostButton && (
				<div className="wheel-price toggle-cost">
					<ShowCostButton showCost={showCost} setCostVisibility={setCostVisibility} />
				</div>
			)}
		</div>
	)
}

export const WheelDetail = {
	Name,
	Finish,
	Series,
	CustomerItemNumber,
	VeloxItemNumber,
	Availability,
	Availability2,
	Shipping,
	InstallationHardware,
	Description,
	Prices
}


export function ProductImage({
	image,
	images,
	show_share,
	share_title,
	share_url
}){
	const [currentImage, setCurrentImage] = useState(0)
	const [isFullScreen, setIsFullScreen] = useState(false)
	const productImageRef = useRef(null)
	const productImageMainRef = useRef(null)

	let hasAltImages = images && images.product360
	let has360Images = images && images.product360

	const IS_VISIBLE = {
		MAIN: has360Images ? currentImage === 0 : true,
		PRODUCT360: has360Images && (currentImage === 1)
	}
	//console.log(IS_VISIBLE)
	let image_360_thumbnail_url = null
	if( has360Images ){
		image_360_thumbnail_url = images.product360.url + images.product360.start + "-150.png"
	}

	function handleFullScreenChange(e){
		setIsFullScreen(!!document.fullscreenElement)
	}

	function toggleFullScreen(){
		if( isFullScreen ){
			document.exitFullscreen()
		}else if(productImageRef){
			productImageRef.current.requestFullscreen()
		}else{
			console.error('Toggle fullscreen failed', productImageRef)
		}
	}

	const view360Image = () => setCurrentImage(1)
	const viewMainImage = () => setCurrentImage(0)
	const go_image = (i) => setCurrentImage(i)

	useEffect(() => {
		document.addEventListener('fullscreenchange', handleFullScreenChange)
		return () => {
			document.removeEventListener('fullscreenchange', handleFullScreenChange)
		}
	}, [])

	return (
		<div className={"product-image-wrapper" + (hasAltImages ? " has-alt-images" : "")}>
				<ul className="product-alt-images">
					{ has360Images && (
						<li
							className={"product-alt-image-thumbnail product-alt-image-360-thumbnail" + (IS_VISIBLE.PRODUCT360 ? " is-active" : "")}
							data-target="product-image-360"
							onMouseOver={view360Image}
							onClick={view360Image}
						>
							<div className="thumbnail-overlay">
								<img src="https://cdn.veloxwheels.com/product-images/360/product-360-thumbnail-overlay.png" alt="" />
							</div>
							<img src={image_360_thumbnail_url} />
						</li>
					)}
					<li
						className={"product-alt-image-thumbnail" + (IS_VISIBLE.MAIN ? " is-active" : "")}
						data-target="product-image-main"
						onMouseOver={viewMainImage}
						onClick={viewMainImage}
					>
						<img src={image} alt="Product Main" />
					</li>
				</ul>
				<div
					className={"product-image" + ( isFullScreen ? " is-fullscreen" : "")}
					ref={ productImageRef }
				>
					<div
						className="product-image-main"
						ref={ productImageMainRef }
					>
					<WheelImage
						image={image}
						images={images}
						className="product-image-main"
						type="large"
						placeholder_style={{ width: 350, height: 350 }}
						visible={IS_VISIBLE.MAIN}
					/>
					{ has360Images && (
						<Image360
							{...images.product360}
							className="product-image-360"
							visible={IS_VISIBLE.PRODUCT360}
						/>
					)}
				</div>
				<div className="image-toolbar">
					<button className="fullscreen-button" onClick={toggleFullScreen}>
						<FullScreenIcon style={{ display: isFullScreen ? "none" : "block" }} />
						<ExitFullScreenIcon style={{ display: isFullScreen ? "block" : "none" }}/>
					</button>
				</div>
				{ show_share && <SocialShareButtons
					title={share_title}
					url={ share_url || window.location.toLocaleString() }
					image={ image.large  }
					label={false}
					round={false}
				/>}
				</div>
			</div>
	)
}
