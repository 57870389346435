import React, { Component } from 'react'

export class ResponsiveSidebar extends Component {
	constructor(breakpoint) {
		super()
		this.MOBILE_BREAKPOINT = breakpoint
	}
	state = {
		width: 0,
		height: 0,
		opened: false
	}
	componentDidMount() {
		this.handle_resize()
		window.addEventListener('resize', this.handle_resize)
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.handle_resize)
	}
	handle_resize = (e) => {
		let { offsetWidth, offsetHeight } = document.body
		let MOBILE_BREAKPOINT = this.MOBILE_BREAKPOINT
		let menu_is_mobile = offsetWidth <= MOBILE_BREAKPOINT,
			is_opened = (offsetWidth >= MOBILE_BREAKPOINT) ? false : this.state.opened,
			menu_is_collapsed = !!menu_is_mobile && !is_opened

		this.setState({
			width: offsetWidth,
			height: offsetHeight,
			opened: is_opened,
			menu_is_mobile: menu_is_mobile,
			menu_is_collapsed: menu_is_collapsed
		})
	}
	show_on_mobile = (event) => {
		event.preventDefault()
		let is_opened = !this.state.opened,
			menu_is_mobile = this.state.menu_is_mobile

		this.setState({ 
			opened: is_opened,
			menu_is_collapsed: !!menu_is_mobile && !is_opened
		})
	}
}