/*
 * Root Reducer file
 * Combines all the reducers into the main application state's reducer.
 */
import { INITIALIZE_API, GET_SERIES_INFO } from './actions'

import VehicleSelectionReducer from './vehicle_select/reducers'
import ResultsReducer from './visualizer/results/reducers'
import VehicleReducer from './visualizer/vehicle/reducers'
import CartReducer from './visualizer/cart/reducers'

import initial_state from './initial_state'

// Root-level reducers. Primarily holds configuration data stored at load-time
function api_reducer(state=null, action){
	if( action.type === INITIALIZE_API ){
		return action.data
	}else{
		return state
	}
}
function series_reducer(state={}, action){
	if( action.type === GET_SERIES_INFO ){
		return action.data
	}else{
		return state
	}
}

// Combined reducer, mounted at the application state
export function root_reducer(state=initial_state, action){
	return {
		form_select: VehicleSelectionReducer(state.form_select, action),
		API: api_reducer(state.API, action),
		results: ResultsReducer(state.results, action),
		vehicle: VehicleReducer(state.vehicle, action),
		cart: CartReducer(state.cart, action),
		series: series_reducer(state.series, action)
	}
}

export default root_reducer