import React, { Component, useContext } from 'react'
import PropTypes from 'prop-types'

import actions from '../../action_creators'
import store from '../../store'

import { ShippingCar, Warehouse } from '../../common/icons'
import { WheelSizeOptionInfo } from '../types'

import './wheel_size_options.scss'
import { VeloxVisualizerContext } from '../../context'
import { CopyIcon } from '../../../../icons'
import { AddToClipboardButton } from '../../common'

const dispatch = store().dispatch

export interface WheelSizeOptionsProps{
	sizes: WheelSizeOptionInfo[];
	model: string;
	finish: string;
	selected: WheelSizeOptionInfo | null;
	is_stocking: boolean;
}

export function WheelSizeOptions({
	sizes,
	model,
	finish,
	selected,
	is_stocking
}: WheelSizeOptionsProps){

	const join_key =  model + '|' + finish
	const { terms } = useContext(VeloxVisualizerContext)

	function set_selected_option(value){
		dispatch(actions.SELECT_WHEEL_OPTION({
			item: join_key,
			option: value
		}))
	}

	return (
		<div className="wheel-size-options">
			<label className="label">Available Options:</label>
			{ !!sizes && (
				<ul className="wheel-options-list">
					{ sizes.map((x, i)=> {
						const { size, width, offset, pcd, item_num, sku, stocking_sku, is_stocked } = x
						const s = selected[join_key]
						const option_selected = ( s && ([s.size, s.width, s.offset, s.item_num].join('|') === [size, width, offset, item_num].join('|')))
						return (
							<li
								className={'wheel-options-list-item ' + (option_selected ? 'is-selected' : '') }
								key={'wheel-option-list-item-' + i}
								onClick={ e => set_selected_option(x) }
								data-option-size={size}
								data-option-width={width}
								data-option-offset={offset}
							>
								<div className="option-description">
									{size}x{width} {(Number(offset) >= 0 ? '+' : '-') + offset} {pcd}
									<div className="option-velox-pn">
										<label className="label">Velox PN#:</label>
										<span className="value">{item_num}</span>
									</div>
								</div>
								<div className="option-stock">
									<div className="stock-online">
										<label className="label">{ terms.partner_sku }#</label>
										<div className="value">
											<div className="value-sku">{sku}</div>
											<AddToClipboardButton value={sku} title={`Copy ${ terms.partner_sku } to clipboard`} />
										</div>
									</div>
									{ (!!is_stocked && !!stocking_sku) && (<div className="stock-instore">
										<label className="label">Stock SKU</label>
										<div className="value">{stocking_sku}</div>
										<div className="availability">
											<Warehouse />
											Check In-Store Inventory
										</div>
									</div>
									)}
								</div>
							</li>
						)
					}) }
				</ul>
			)}
		</div>
	)
}

export default WheelSizeOptions
