import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducers from './reducers'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function create_store(){
	return createStore(
		reducers,
		composeEnhancer(applyMiddleware(thunk))
	)
}

export default (function(){
	var store
	function get_store(){
		store = create_store()
		return store
	}
	return function(){
		if( store ){
			return store
		}else{
			get_store()
			return store
		}
	}
})()