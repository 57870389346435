import _ from 'lodash';
import React, { Component, useContext, useEffect } from 'react';
import { proper } from 'velox-visualizer-tools';

import { VeloxVisualizerContext } from '../../context'
import actions from '../../action_creators';
import Components from '../../common';
import store from '../../store';
import { ProductImage } from './product_image';
import { WheelDetail } from './wheel_detail';
import WheelSizeOptions from './wheel_size_options';
import WheelSpecs from './wheel_specs';

import './wheel_details.scss';

const dispatch = store().dispatch

const { Cancel, ShippingCar, HardwareParts, ShoppingCart, WarningTriangle, QuestionCircle } = Components.Icons
const { QtyDropdown } = Components.Dropdowns
const { AddToCart, SocialShareButtons } = Components.Buttons

function close_details(){
	dispatch( actions.SHOW_DETAILS(false) )
	window.scrollTo(0,0)
}

const wheel_specs_details = ['size', 'offset', 'pcd', 'finish', 'weight', 'construction', 'hub_bore', 'is_all_season', 'suggested_tire_size']

export function WheelDetails({ wheel, vehicle, selected, qty, series_details, checkout_button_text, onCheckout, show_share, min_qty, max_qty, is_stocking, set_quantity, showCost, setCostVisibility }){

	const { terms, showCostButton } = useContext(VeloxVisualizerContext)
	const { partner } = terms

	const { sizes, model, finish, bsro_cost, costco_cost } = wheel
	const join_key = model + '|' + finish
	const selected_option = (selected ? selected[join_key] : {})
	checkout_button_text = (checkout_button_text || 'Add to Cart')

	useEffect(set_default_selected_option, [])
	useEffect(()=>{
		set_default_selected_option()
	}, [model, finish])

	function checkout(e){
		const selected_wheel = _.assign({}, wheel, selected_option)
		delete selected_wheel.sizes

		onCheckout({
			wheel: selected_wheel,
			qty: qty,
			vehicle: vehicle
		})
	}

	function set_default_selected_option(){
		if( sizes && sizes.length ){
			dispatch(actions.SELECT_WHEEL_OPTION({
				item: join_key,
				option: sizes[0]
			}))
		}
	}
	
	console.log(wheel)
	
	return (
		<section className={'product-details' + (is_stocking ? ' is-stocking' : ' is-not-stocking' )}>
			<header className='product-details-header'>
				<div className='close-details' onClick={ e => close_details() }>
					<Cancel />
					<span className='vxviz-label'>Close Details</span>
				</div>
			</header>
			<section className='product-details-main'>
				<div className="product-details-image">
					<ProductImage
						images={wheel.images}
						show_share={show_share}
						share_title={`Velox ${proper(wheel.series)} ${wheel.model} ${proper(wheel.finish_description)}`}
					/>
					<small className="disclaimer">Wheel images shown depict style only. Actual finish and bolt pattern may have slight variance from stock photo.</small>
				</div>
				<div className='product-details-main-content'>
					<header className='product-details-main-content-header'>
						<div className='left'>
							<div className='product-item-number'>
								<WheelDetail.CustomerItemNumber>{ wheel.sku }</WheelDetail.CustomerItemNumber>
							</div>
							<WheelDetail.Name>{ wheel.model }</WheelDetail.Name>
							<WheelDetail.Finish>{ wheel.finish_description }</WheelDetail.Finish>
						</div>
						<div className='right'>
							<WheelDetail.Series>{ wheel.series }</WheelDetail.Series>
						</div>
					</header>
					<ul className='product-details-main-content-list'>
						<li className='product-price'>
							<WheelDetail.Prices
								original_price={wheel.price}
								current_price={wheel.current_price}
								showCost={showCost}
								cost={wheel.cost}
								currentCost={wheel.current_cost}
								setCostVisibility={setCostVisibility}
							/>
							<div className='wrapper'>
								<WheelDetail.Availability is_available={wheel.is_available} />
							</div>
						</li>
						<li className='product-options'>
							<WheelSizeOptions { ...wheel } selected={selected} is_stocking={is_stocking} />
						</li>
						<li className='product-details-main-actions'>
							<label className='vxviz-label'>Quantity</label>
							<div className='select-quantity-wrapper'>
								<QtyDropdown min={ min_qty || 1 } max={ max_qty || wheel.available } onChange={ set_quantity } />
								<div className='checkout-button'>
									<button className='btn green btn-purchase' onClick={ checkout }>
										<ShoppingCart />
										{ checkout_button_text }
									</button>
								</div>
							</div>
							{ !is_stocking && (
								<div className='select-quantity-warning'>
									<QuestionCircle />
									<p className="select-quantity-warning-text">
										<strong className="text-main">Looking for a quantity less than 4?</strong>
										<br />
										<span className="text-details">Please contact Velox Customer Support at <a href="tel:1-877-999-4335">1-877-999-4335</a> during our normal business hours or via email at <a className="contact-info-link contact-info-email" href="mailto:support@veloxwheels.com">support@veloxwheels.com</a>.</span>
									</p>
								</div>
							)}
						</li>
					</ul>
				</div>
			</section>
			<section className='product-details-info'>
				<div className='wrapper'>
					<div className='product-details-info-description'>
						<h3 className='title'>Description</h3>
						<WheelDetail.Description content={ wheel.detail_description } />
					</div>
					<div className='product-details-info-specs'>
						<h3 className='title'>Specifications</h3>
						<WheelSpecs
							wheel={wheel}
							vehicle={vehicle}
							option={selected_option}
							specs={ wheel_specs_details }
						/>
					</div>
				</div>
			</section>
		</section>
	)
}

export default WheelDetails
