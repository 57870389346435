import '../common'
import $ from 'jquery'
import './app.scss'

function init_navigation_toggler(){
	$('.navbar-toggle').click(function(){
		var $toggler = $(this),
			$navigation = $($toggler.data('target'))
		$toggler.toggleClass('collapsed')
		$navigation.toggleClass('show')
	})
}

function init_dropdowns(){
	let $dropdownToggle = $('.dropdown-toggle')
	if( $dropdownToggle ){ $dropdownToggle.dropdown() }
	/* Bootstrap v5
	var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
	var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
		return new Dropdown(dropdownToggleEl)
	})
	*/

	$('.main-navigation .dropdown').hover( e =>{
		let el = $(e.target)
		let toggle = el.children('.dropdown-toggle')
		let dropdown_menu = el.children('.dropdown-menu')
		el.toggleClass('show')
		dropdown_menu.toggleClass('show')
		//el.dropdown('toggle')
	})
}
$(document).ready(function(){
	init_navigation_toggler()
	init_dropdowns()

	console.log('Application initialized')
})

import './dashboard'
