import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { Dropdown } from '../../common'
import { plural, proper, VEHICLE_SELECTION_DROPDOWNS } from 'velox-visualizer-tools'

import './vehicle_select_dropdown.scss'

export class VehicleSelectionFormDropdown extends Component{
	/*
	static propTypes = {
		name: PropTypes.string.isRequired,
		options: PropTypes.array,
		loading: PropTypes.bool,
		selected: PropTypes.oneOfType([  PropTypes.string,  PropTypes.number ]),
		onChange: PropTypes.func.isRequired,
		set_ref: PropTypes.func,
		style: PropTypes.object
	}
	*/
	shouldComponentUpdate(next_props, next_state) {
		let selected_changed = !_.isEqual(this.props.selected, next_props.selected)
		let values_changed = !_.isEqual(this.props.options, next_props.options)
		let loading_changed = this.props.loading !== next_props.loading
		return selected_changed || values_changed || loading_changed
	}
	render(){
		var { name, options, loading, selected, onChange, set_ref, style } = this.props
		var plural_name = plural(name)
		let next_field = VEHICLE_SELECTION_DROPDOWNS.after(name)
		return (
			<Dropdown
	          name={ plural_name }
	          data={ options || [] }
	          loading={ loading }
	          value={ selected }
	          defaultValue={'Select ' + proper(name) }
	          placeholder={ 'Select ' + proper(name) }
	          onChange={ onChange }
	          className={ this.className() }
	          //caseSensitive={ false }
	          messages={ this.messages }
	          duration={ 100 }
	          disabled={ !options || !options.length }
	          set_ref={ set_ref }
	          style={ style }
	        />
		)
	}
	messages = {
		emptyList: 'No items found.'
	}
	className = () => {
		var c  = ['velox-vehicle-selection-form-dropdown']
		if( !!this.props.loading ){
			c.push('is-loading')
		}else{
			c.push('is-not-loading')
		}
		if(!this.props.options || !this.props.options.length){
			c.push('is-empty')
		}
		if( !!this.props.selected ){
			c.push('is-selected')
		}
		if( this.props.className ){
			c.push(this.props.className)
		}
		return c.join(' ')
	}
}
export default VehicleSelectionFormDropdown
