import _ from 'lodash'
import $ from 'jquery'
import React from 'react'
import ReactDOM from 'react-dom'
import history from '../history'
import VeloxVisualizerModule, { VeloxVisualizerApp, COOKIES, ACTIONS, ACTION_CREATORS, STORE } from './Visualizer'
const { SelectedQtyCookie, SelectedVehicleCookie, SelectedWheelCookie } = COOKIES

import '../styles/_visualizer_fixes.scss'

var VeloxVisualizer = VeloxVisualizerModule()

export var Visualizer = {
	CONFIG: {
		min_qty: 4,
		max_qty: 4,
		show_share: false,
		api: '/api',
		onCheckout: function(order){
			console.info(order);
			let vehicle_id = order.vehicle.id,
				product_item_num = order.wheel.item_num,
				qty = order.qty
			var path = `/order/confirm-item-selection/?vehicle=${vehicle_id}&wheel=${product_item_num}&qty=${qty}`;
			//console.log(path);
			//console.log(window.location);
			var origin = window.location.origin || `${window.location.protocol}//${window.location.host}`
			window.location = `${origin}${path}`;
		},
		onSelectVehicle: function(results){
			let vehicle_id = results.vehicle.id
			history.push(`/order/select-wheel/?vehicle=${vehicle_id}`)
		}
	},
	render: function(element, overrides={}){
		let config = { ...this.CONFIG, ...overrides }
		VeloxVisualizer.render_visualizer(element, config)
		return element
	},
	initialize: function(selector='#vehicle-visualizer-results', overrides={}){
		let $element = $(selector),
			element = $element[0]
		if( element ){
			this.restore_server_state()
			this.render(element, overrides)
			console.info('Vehicle Visualizer initialized')
		}
	},
	restore_server_state: function(){
		let serverside_results_json = window.velox_vehicle_results;
		if( serverside_results_json ){
			let { vehicle, wheels } = serverside_results_json

			// Replace vehicle cookie to prevent visualizer from restoring from cookie
			let vehicle_cookie = _.pick(vehicle, ['id', 'make', 'model', 'year', 'size', 'diameter'])
			vehicle_cookie.size =  vehicle_cookie.size || vehicle_cookie.diameter
			SelectedVehicleCookie.set(vehicle_cookie)

			// Load the server data
			STORE.dispatch( ACTION_CREATORS.DEFAULTS[ ACTIONS.RESTORE_VEHICLE_SELECTION ](vehicle))
			STORE.dispatch( ACTION_CREATORS.DEFAULTS[ ACTIONS.LOAD_RESULTS ](serverside_results_json) )
		}
	}
}

export var VehicleSelectionForm = {
	CONFIG: {
		onSubmit: (results) => console.log(results),
		layout: 'grid',
		api: '/api'
	},
	render: function(element, overrides={}){
		let config = _.assign({}, this.CONFIG, overrides)
		VeloxVisualizer.render_vehicle_selection_form(element, config)
		return element
	},
	initialize: function(selector='#vehicle-selection-form', overrides={}){
		let jquery_element = $(selector),
			element = jquery_element[0]
		if( element ){
			var layout = jquery_element.data('layout')
			if( layout ){
				overrides.layout = layout
			}
			this.render(element, overrides)
			console.info('Vehicle Selection Form initialized')
		}
	}
}
