import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { CONFIG, css_class, lower } from 'velox-visualizer-tools';

import { ErrorBoundary } from './error_boundary';
import ImageIfExists from './image_if_exists';

const { BRAND_BUCKET, PRODUCTS_BUCKET, IMG_DIR_BASE } = CONFIG

export const LOGO = {
    image: { src: IMG_DIR_BASE + "/brand/logo-velox-white-200.png", alt: "Velox logo" },
    image_grey: { src: IMG_DIR_BASE + "/brand/logo-velox-grey320.png", alt: "Velox logo" },
    link: "/"
}
export const PLACEHOLDER_WHEEL = "https://cdn.veloxwheels.com/product-images/wheel-loading-placeholder-300.png"


export interface VeloxLogoProps{
	link: string;
}
export function VeloxLogo({ link }: VeloxLogoProps){
	return (
		<Link className="logo" to={link}>
			<img src={LOGO.image.src} alt={LOGO.image.alt} />
		</Link>
	)
}

export function VeloxLogoGrey({ link }: VeloxLogoProps){
	return (
		<Link className="logo" to={link}>
			<img src={LOGO.image_grey.src} alt={LOGO.image_grey.alt} />
		</Link>
	)
}


export interface SeriesLogoProps{
	series: string;
	className?: string;
	onClick?: MouseEventHandler;
}
export function SeriesLogo({ series, onClick, className }: SeriesLogoProps){
	if(!series){
		return <div></div>
	}else{
		series = lower(series)
		return (
			<div className={ `wheel-series-logo series-${css_class(series)} ${ className || '' }` } onClick={onClick}>
				<img src={ BRAND_BUCKET + '/series-' + series.replace(/ /g, '') + '.png' } alt={ series + ' logo' } />
			</div>
		)
	}
}

export function ImageZoom(props) {
  let { zoom, src, alt, background } = props
  const zoomRef = React.useRef(zoom.clone({ background }))

  function attachZoom(image) {
    zoomRef.current.attach(image)
  }

  return <img {...props} ref={attachZoom}  />
}


export class WheelImage extends ErrorBoundary{
	static defaultProps = {
		images: {},
		url: null,
		visible: true,
		type: 'large',
		alt: null
	}
	image_ref = null
	on_image_load = (load_state) => {
		if( _.hasIn(load_state, 'loaded')){
			//console.log(load_state)
			this.setState({
				loaded: load_state.loaded,
				exists: load_state.exists
			})
			if( this.props.onImageLoad ){ this.props.onImageLoad() }
		}
	}
	className(){
		let component_classes = ['wheel-image']
		component_classes.push(this.state.loaded ? "is-loaded" : "is-not-loaded")
		component_classes.push(this.props.visible ? "is-visible" : "is-not-visible")
		if( this.props.className ){ component_classes.push(this.props.className) }
		return component_classes.join(' ')
	}
	set_image_ref = (e) =>{
		this.image_ref = e
	}
	constructor(props){
		super(props)
		this.state = {
			hasError: false,
			loaded: false,
			exists: null,
			image_url: null
		}
	}
	/*
    get_image_url = () => {
        let { image, images, type } = this.props
		if (!images.hd && images.large && type=="hd" ){
			return images.large.replace('600','1000')
		}else{
			return images[type || "large"]
		}
    }
	constructor(props){
		super(props)
		let { image, images, type } = props
		let image_url = images[type || "large"]
		if (!images.hd && images.large && type=="hd" ){
			image_url = images.large.replace('600','1000')
		}
		this.state.image_url = image_url
	}
	shouldComponentUpdate = (next_props, next_state) => {
		let is_image_changed = !_.isEqual(next_props.images, this.props.images)
		let is_type_changed = next_props.type !== this.props.type
		return !_.isEqual(this.props, next_props) || !_.isEqual(this.state, next_state)
	}
	componentDidupdate(prev_props, prev_state, snapshot){
		let is_image_changed = !_.isEqual(prev_props.images, this.props.images)
		let is_type_changed = prev_props.type !== this.props.type
		console.log(this.props, {is_image_changed, is_type_changed})
		if( is_image_changed || is_type_changed ){
			let image_url = this.get_image_url()
			this.setState({ image_url })
		}
	}
	*/
	render(){
		let { model, finish, images, onClick, type, visible, url, alt } = this.props
		let { image_url } = this.state
		if( this.state.hasError ){
			return (
				<div className='wheel-image has-error'>Error</div>
			)
		}
		if( images ){
            return (
				<div className={this.className()} onClick={onClick}>
					<ImageIfExists
						src={ url }
						alt={alt}
						ref={ e => this.set_image_ref(e) }
						//fallback={<WheelImagePlaceholder />}
						onLoad={ this.on_image_load }
					/>
				</div>
			)
		}else{
			return (<div></div>)
		}
	}
}
