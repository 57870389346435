import { useEffect, useRef, useState } from "react";
import { is_device_mobile } from "velox-visualizer-tools";

import circlr from "circlr";
import { Image360Props } from "./image360";

export function useImage360(props: Image360Props) {
  let {
    frames,
    url,
    images,
    direction,
    cycle,
    cycles,
    start,
    autoplay,
    format,
    scroll,
    interval,
    visible,
    onRotate,
    zoom,
  } = props;

  // Default prop values
  frames = frames || 0;
  direction = direction || 0;
  cycle = typeof cycle === "undefined" ? true : cycle;
  start = start || 0;
  autoplay = typeof autoplay === "undefined" ? true : autoplay;
  format = format || "png";
  scroll = typeof scroll === "undefined" ? true : scroll;
  interval = typeof interval === "undefined" ? 75 : interval;
  images = images || [];

  // Hooks
  const [loadedImageCount, setLoadedImageCount] = useState(0);
  const [loadedImages, setLoadedImages] = useState<string[]>([]);
  const [currentImage, setCurrentImage] = useState(start);
  const [hasError, setHasError] = useState(false);
  const [errorAttempts, setErrorAttempts] = useState(0);
  const [circlrElement, setCirclrElement] = useState(null);
  const [animateLoadTimer, setAnimateLoadTimer] = useState(null);
  const [isVisible, setIsVisible] = useState(visible);

  const imageWrapperRef = useRef(null);

  const imageRefArr = [];
  for (let i = 0; i < 25; i++) {
    imageRefArr.push(useRef(null));
  }
  const imageRefs = useRef(imageRefArr);

  // Configuration/Constants
  const IS_MOBILE_DEVICE = is_device_mobile();
  const PIXELS_PER_DEGREE = 12;
  const PIXELS_PER_IMAGE = PIXELS_PER_DEGREE * (360 / frames);
  const TOTAL_FRAMES_PER_ROTATION = frames * cycles;

  const loadingPercent = Math.floor((loadedImageCount / frames) * 100);
  const isReverse = direction < 0;

  // set classname
  let classNames =
    "image-360-viewer" + (visible ? " is-visible" : " is-not-visible");
  classNames = classNames + (IS_MOBILE_DEVICE ? " is-mobile-device" : "");
  classNames =
    classNames + (loadedImageCount ? " is-loaded" : " is-not-loaded");

  function reset() {
    if (circlrElement) {
      circlrElement.unbind();
    }
    const imageElements: NodeListOf<HTMLImageElement> =
      document.querySelectorAll(".image-360-viewer-images img");
    if (imageElements && imageElements.length) {
      imageElements.forEach((e) => {
        if (e) {
          e.style.display = null;
        }
      });
    }
    if (animateLoadTimer) {
      if (circlrElement) {
        circlrElement.stop();
      }
      clearTimeout(animateLoadTimer);
      setAnimateLoadTimer(null);
    }
  }

  function initialize() {
    try {
      if (animateLoadTimer) {
        if (circlrElement) {
          circlrElement.stop();
        }
        clearTimeout(animateLoadTimer);
        setAnimateLoadTimer(null);
      }
      if (imageWrapperRef && imageWrapperRef.current) {
        setCirclrElement(
          circlr(imageWrapperRef.current)
            .scroll(scroll)
            .start(start)
            .reverse(!isReverse)
            .cycle(cycle)
            .interval(interval)
            .on("show", setCurrentImage)
        );
        setHasError(false);
        setErrorAttempts(0);
      }
    } catch (e) {
      console.error(e);
      if (errorAttempts < 5) {
        setTimeout(initialize, 1000);
        setCirclrElement(null);
        setHasError(true);
        setErrorAttempts(errorAttempts + 1);
      }
    }
  }

  async function animateLoad() {
    return new Promise((resolve, reject) => {
      try {
        if (circlrElement && isVisible) {
          circlrElement.show(start).play(frames);

          setAnimateLoadTimer(
            setTimeout(() => {
              if (circlrElement) {
                circlrElement.stop();
              }
              resolve(currentImage);
            }, interval * (frames - 1))
          );
        } else {
          //console.error('animateLoad: element not found', circlrElement)
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  function onImageLoad(url: string) {
    if (!loadedImages.includes(url)) {
      const newLoadedImages = [].concat(loadedImages).concat(url);
      setLoadedImages(newLoadedImages);
      setLoadedImageCount(newLoadedImages.length);
    }
  }

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  useEffect(() => {
    initialize();
    if (isVisible && images && images.length) {
      animateLoad();
    }
    return reset;
  }, [isVisible, images]);

  return {
    frames,
    url,
    images,
    direction,
    cycle,
    cycles,
    start,
    autoplay,
    format,
    scroll,
    interval,
    visible,
    onRotate,
    zoom,
    loadedImageCount,
    setLoadedImageCount,
    loadedImages,
    setLoadedImages,
    currentImage,
    setCurrentImage,
    hasError,
    setHasError,
    errorAttempts,
    setErrorAttempts,
    circlrElement,
    setCirclrElement,
    imageWrapperRef,
    imageRefs,
    IS_MOBILE_DEVICE,
    PIXELS_PER_DEGREE,
    PIXELS_PER_IMAGE,
    TOTAL_FRAMES_PER_ROTATION,
    loadingPercent,
    isReverse,
    classNames,
    reset,
    initialize,
    animateLoad,
    onImageLoad,
  };
}
