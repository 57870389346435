import _ from 'lodash'
export const remove_wheel_columns = []

export function group_wheels(wheels, sort=[]){
	if( wheels && wheels.length ){
		//console.log(wheels)
		var wheel_groups = _.groupBy(wheels, x => {
			//console.log( x )
			return x.model + '|' + x.finish
		})
		var grouped_wheels = _.values(wheel_groups).map( group => {
			let sizes = _.map(group, x => {
				return {
					item_num: x.item_num,
					size: x.diameter,
					width: x.width,
					offset: x.offset,
					sku: x.sku,
					stocking_sku: x.stocking_sku,
					pcd: x.pcd,
					is_stocked: x.is_stocked
				}
			})
			//x => x.size + '|' + x.width + '|' + x.offset
			sizes = _.uniqWith( sizes, _.isEqual )
			let result = _.assign({}, group[0])
			remove_wheel_columns.forEach( col => { delete result[col] })
			result.sizes = sizes
			return result
		})
		grouped_wheels = _.sortBy(grouped_wheels, sort)
		return grouped_wheels
	}else{
		//console.log('No wheels to group.')
		return []
	}
}

const FILTER_REGEXP =  /^([a-zA-Z0-9_\w]*)\|(.*)$/ig // matches 'width|8', etc

export function does_wheel_match_filter(wheel, filters, filters_map){
	if( !filters.length ){
		return true
	}

	// For each filter key, check if wheel matches any of its values
	var match_results = Object.keys(filters_map).map( key => {
		if( filters_map[key] && filters_map[key].length ){
			if( !_.isBoolean(wheel[key]) && !isNaN(wheel[key]) ){
				wheel[key] = Number(wheel[key])
			}
			return _.reduce(filters_map[key], (result, value) => {
				if( !_.isBoolean(value) && !isNaN(value) ){
					value = Number(value)
				}
				// eslint-disable-next-line eqeqeq
				result = result || (wheel[key] == value)
				return result
			}, false)
		}else{
			return true
		}
	})

	var final_result = _.reduce(match_results, (result, bool) => {
		return result && bool
	}, true)
	return final_result
}
