import React from "react"
import { VisualizerAppContext } from "./types"
import * as config from './config'

export const DEFAULT_CONFIG : VisualizerAppContext = {
    ...config,
    min_qty: 4,
    max_qty: 3,
    is_stocking: false,
    checkout_button_text: 'Add to Cart',
    show_share: false,
    showCostButton: false,
    terms: {
      partner: 'Costco',
      partner_sku: 'Costco Item'
    },
    vehicle_selection_form: {
      path: '/select',
      restore_from_cookie: true,
      is_stocking: false
    },
    visualizer: {
      path: '/visualizer',
      restore_from_cookie: true,
      is_stocking: false
    }
  }
  
  export const VeloxVisualizerContext = React.createContext(DEFAULT_CONFIG)
  