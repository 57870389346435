import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import mediumZoom from 'medium-zoom'

import Components, { PriceStyled } from '../../common'
import { proper, currency } from 'velox-visualizer-tools'
import { Image360WithErrorHandling } from '../../product360/image360'
const Slider = require("react-slick").default

const { AddToCart, SocialShareButtons } = Components.Buttons
const { Cancel, ShippingCar, HardwareParts, CheckmarkCircle, FullScreenIcon, ExitFullScreenIcon, SliderArrow } = Components.Icons
const { SeriesLogo, WheelImage } = Components.Images

export class ProductImage extends Component{
	state = {
		current_image: 0,
		is_fullscreen: false,
		is_main_img_loaded: false,
		num_images: 1,
		has_alt_images: false,
		has_360: false,
		has_video: false,
		current_image_url: null,
		product_image_styles: {}
	}
	zoom = null
	ref = {}
	set_ref = (label, e) =>{ this.ref[label] = e }
	go_image = (i) => { this.setState({ current_image: i })}
	view_main_img = () => this.setState({ current_image: 0 })
	view_360_img = () => this.setState({ current_image: 1 })
	view_video = () =>  this.setState({ current_image: 2 })
	view_prev = () => {
		let { current_image, num_images } = this.state
		let next = ((current_image - 1) % num_images )
		if( next < 0 ){ next = next + num_images }

		this.setState({ current_image: next })
	}
	view_next = () => {
		let { current_image, num_images } = this.state
		let next = ((current_image + 1) % num_images )
		if( next < 0 ){ next = next + num_images }
		this.setState({ current_image: next })
	}
	toggle_fullscreen = () => {
		let { is_fullscreen } = this.state
		let e = this.ref['product-image-wrapper']
		this.zoom.toggle()
		if( document.fullscreenEnabled ){
			if( is_fullscreen ){
				document.exitFullscreen()
			}else{
				e.requestFullscreen()
			}
		}else{
			let current_image = document.querySelector('.product-image-main .wheel-image.is-visible img')
			current_image = current_image || document.querySelector('.image-360-viewer img.is-visible')
			let current_image_url = current_image.getAttribute('src').replace('-600','-1000')
			window.open(current_image_url, '_blank')
		}
	}
	toggle_body_fullscreen_class = () =>{
		let root = document.getElementsByTagName( 'html' )[0]
		let root_classes = root.className
		let root_is_fullscreen = root_classes.indexOf('is-fullscreen')
		//console.log({ root, root_classes, root_is_fullscreen })
		if( root_is_fullscreen ){
			//console.log('need to remove fullscreen class')
			// remove the is-fullscreen class
			root.className = root.className.replace('is-fullscreen','').replace('  ')
		}else{
			//console.log('need to add fullscreen class')
			root.className += ' is-fullscreen'
			//console.log(root.className)
		}
		//console.log(root.className)
	}
	handle_fullscreen_change = (e) => {
		let root = document.getElementsByTagName( 'html' )[0]
		let root_classes = root.className
		let root_is_fullscreen = root_classes.indexOf('is-fullscreen')
		if( document.fullscreenElement ){
			let container = {
				width: document.documentElement.clientWidth,
				height: document.documentElement.clientHeight,
				left: 0,
				top: 0,
				right: 0,
				bottom: 0,
			}
			let current_image = document.querySelector('.product-image-main .wheel-image.is-visible img')
			current_image = current_image || document.querySelector('.image-360-viewer img.is-visible')
			let current_image_url = current_image.src
			current_image.src = current_image_url.replace('-600','-1000')

			// for 360 images, all images need to be replaced with the hd version
			if( this.state.current_image == 1 ){
				document.querySelectorAll('.image-360-viewer img').forEach(img => {
					img.src = img.src.replace('-600','-1000')
				})
			}
			let image_height = window.screen.height - 18
			let image_scale_ratio = image_height / current_image.naturalHeight
			let image_width = image_height
			//let image_width = current_image.naturalWidth * image_scale_ratio
			//console.log({ image_height, image_width, current_image })
			this.setState({
				is_fullscreen: true,
				product_image_styles: {
					height: image_height,
					width: image_width
				}
			})
			root.className += ' is-fullscreen'
		}else{
			root.className = root.className.replace('is-fullscreen','').replace('  ', '')
			this.setState({
				is_fullscreen: false,
				product_image_styles: {}
			})
		}
	}
	on_rotate = (image360_state) => {
		if( image360_state ){
			let current_image_url = image360_state.images[ image360_state.current ]
			this.setState({
				current_image_url: current_image_url
			})
		}
	}
	send_ga_event = (o) => {
		try{
			if( window.ga ){
				window.ga('send',
					_.merge({
						hitType: 'event',
						eventCategory: 'ProductImage',
						eventAction: null,
						eventLabel: null
					}, o)
				)
			}
		}catch(e){
			console.error(e)
		}
	}
	constructor(props){
		super(props)
		let { images } = props
		this.state.has_alt_images = images && images.product360
		this.state.has_360 = images && images.product360
		this.state.has_video = images && images.video
		if( this.state.has_360 ){
			this.state.num_images = 2
		}
		this.zoom = mediumZoom()
	}
	componentDidMount = () =>{
		if( document.fullscreenEnabled ){
			document.addEventListener('fullscreenchange', this.handle_fullscreen_change)
		}
		let { images } = this.props

		let new_state = {
			has_360: !!(images && images.product360),
			has_alt_images: !!(images && (images.product360 || images.video)),
			has_video: !!(images && images.video),
			current_image: 0,
			is_fullscreen: false,
			num_images: 1
		}
		if( new_state.has_360 ){
			new_state.current_image = 1
		}

		if( new_state.has_360 ){ new_state.num_images = new_state.num_images + 1 }
		if( new_state.has_video ){ new_state.num_images = new_state.num_images + 1 }
		this.setState(new_state)
	}
	componentDidUpdate = (prev_props, prev_state, snapshot) =>{
		let is_image_changed = !_.isEqual(prev_props.images, this.props.images)
		let is_visibility_changed = !_.isEqual(prev_state.current_image, this.state.current_image)
		let { short_description } = this.props
		if( is_image_changed ){
			let images = this.props.images
			let new_state = {
				has_360: !!(images && images.product360),
				has_alt_images: !!(images && (images.product360 || images.video)),
				has_video: !!(images && images.video),
				current_image: 0,
				is_fullscreen: false
			}
			if( new_state.has_360 ){
				new_state.current_image = 1
			}

			new_state.num_images = 1
			if( new_state.has_360 ){ new_state.num_images = new_state.num_images + 1 }
			if( new_state.has_video ){ new_state.num_images = new_state.num_images + 1 }
			this.setState(new_state)
		}
		if( is_visibility_changed ){
			// view 360
			if( this.state.current_image == 1 ){
				this.send_ga_event({
					eventAction: 'view_360',
					eventLabel: short_description
				})
			}

			if( this.state.current_image == 0 ){
				this.send_ga_event({
					eventAction: 'view_main_img',
					eventLabel: short_description
				})
			}
		}
	}
	on_main_img_load = () => this.setState({ is_main_img_loaded: true })
	render(){
		let show_share = this.props.show_share
		let { image, images } = this.props
		let { current_image, is_fullscreen, product_image_styles, is_main_img_loaded, has_360, has_alt_images, has_video } = this.state
		let set_ref = this.set_ref
		let zoom = this.zoom

		const IS_VISIBLE = {
			MAIN: current_image == 0,
			PRODUCT360: has_360 && (current_image == 1),
			VIDEO: current_image == 2
		}

		//console.log(this.state)
		let image_360_thumbnail_url = null
		if( images && images.product360 ){
			image_360_thumbnail_url = images.product360.url + images.product360.start + "-150.png"
		}
		return (
			<div
				className={"product-image-wrapper" + (has_alt_images ? " has-alt-images" : "")  + ( is_fullscreen ? " is-fullscreen" : "") + (document.fullscreenEnabled ? " is-fullscreen-enabled" : " is-fullscreen-disabled")}
				ref={ e =>  set_ref('product-image-wrapper', e) }
			>
				<ul className="product-alt-images">
					{ has_360 && (
						<li
							className={"product-alt-image-thumbnail product-alt-image-360-thumbnail" + (IS_VISIBLE.PRODUCT360 ? " is-active" : "")}
							data-target="product-image-360"
							onClick={this.view_360_img}
						>
							<div className="thumbnail-overlay">
								<img src="https://cdn.veloxwheels.com/product-images/360/product-360-thumbnail-overlay.png" />
							</div>
							<img src={image_360_thumbnail_url} />
						</li>
					)}
					<li
						className={"product-alt-image-thumbnail" + (IS_VISIBLE.MAIN ? " is-active" : "")}
						data-target="product-image-main"
						onClick={this.view_main_img}
					>
						<img src={images.large} />
					</li>
					{ has_video && images.video && images.video.thumbnail && (
						<li
							className={"product-alt-image-thumbnail product-alt-image-video-thumbnail" + (IS_VISIBLE.VIDEO ? " is-active" : "")}
							data-target="product-image-video"
							onClick={this.view_video}
						>
							<img src={images.video.thumbnail} />
						</li>
					)}
				</ul>
				<div
					className={"product-image"}
					ref={ e =>  set_ref('product-image', e) }
					style={product_image_styles}
				>
					<div
						className="product-image-main"
						ref={ e =>  set_ref('product-image-main', e) }
					>
						<WheelImage
							url={ images.large }
							className="product-image-main"
							placeholder_style={{ width: 350, height: 350 }}
							visible={IS_VISIBLE.MAIN}
							onImageLoad={ this.on_main_img_load }
						/>
						{ !!has_360 && !!is_main_img_loaded && (
							<Image360WithErrorHandling
								{...images.product360}
								className="product-image-360"
								visible={IS_VISIBLE.PRODUCT360}
							/>
						)}
						{ !!has_video && !!images.video && !!images.video.url && (
							<div className={"product-image-video" + (IS_VISIBLE.VIDEO ? " is-visible" : " is-not-visible")}>
							<iframe src={images.video.url} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
							</div>
						)}
					</div>
				</div>
				<div className="product-image-toolbar">
					<button className="fullscreen-button" onClick={this.toggle_fullscreen}>
						<FullScreenIcon style={{ display: (is_fullscreen || IS_VISIBLE.VIDEO) ? "none" : "block" }} />
						<ExitFullScreenIcon style={{ display: (is_fullscreen && !IS_VISIBLE.VIDEO) ? "block" : "none" }}/>
					</button>
				</div>
				{ show_share && <SocialShareButtons
					title={`Velox ${proper(this.props.series)} ${this.props.model} ${proper(this.props.finish_description)}`}
					url={ this.props.share_url || window.location.toLocaleString() }
					image={ this.props.image.large }
					label={false}
					round={false}
				/>}
			</div>
		)
	}
}

export default ProductImage
