import React, { Component } from 'react'

import { restore_vehicle, initialize_api } from '../action_creators'
import { resolve_vehicle_cookie  } from 'velox-visualizer-tools'
import VeloxAPI from '../api'

export class ModuleConfigurableComponent extends Component{
	constructor(LABEL){
		super()
		this.class_label = LABEL
		this.module_class_name ='has-' + this.class_label
	}
	get_classNames = () => {
		var config = this.props.config,
			component_classes  = [ this.class_label ]

		if( config && config.className ){
			component_classes.push(config.className)
		}
		return component_classes
	}
	initialize() {
		const { dispatch, config } = this.props
		const token = this.props.token || ( config && config.token )
		const api_url = this.props.api_url || ( config && config.api_url )
		dispatch( initialize_api( VeloxAPI( token, api_url)) )

		var restore_from_cookie = ( config && config.restore_from_cookie) ? config.restore_from_cookie : true
		if( restore_from_cookie ){
			resolve_vehicle_cookie()
				.then( vehicle => dispatch(restore_vehicle(vehicle)) )
				.catch( e => {
					if( window.DEBUG ){ console.error(e) }
				})
		}
		if( config && config.componentDidMount ){
			config.componentDidMount()
		}
	}
	componentWillUnmount() {
		if( this.props.config && this.props.config.componentWillUnmount ){
			this.props.config.componentWillUnmount()
		}
		document.body.className = document.body.className.replace(this.module_class_name, '')
	}
	componentDidMount() {

		this.initialize()

		if( document.body.className.indexOf(this.module_class_name) < 0 ){
			if(document.body.className.length > 0 && document.body.className[document.body.className-1] !== ' '){
				document.body.className += ' '
			}
			document.body.className += this.module_class_name
		}
	}
}
