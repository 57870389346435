import _ from 'lodash'
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { BrowserRouter, HashRouter, Redirect } from 'react-router-dom';


import { resolve_vehicle_cookie, upper } from 'velox-visualizer-tools';

import { restore_vehicle } from './core/action_creators';
import { DEFAULT_CONFIG, VeloxVisualizerContext } from './core/context';
import store from './core/store';
import { VehicleCookie, VisualizerAppProps } from './core/types';


import VehicleSelectonFormModule from './core/vehicle_select'
import VisualizerModule from './core/visualizer'

const Store = store()

/**
 *  Root wrapper component that initializes the application then
 *  redirects to the appropriate route depending on the state
 */
export function RootApp({
  config
}: VisualizerAppProps){
  const [hasVehicle, sethasVehicle] = useState(false)
  const [initialized, setInitialized] = useState(false)

  const { vehicle_selection_form, visualizer } = config

  async function restoreFromCookie(){
    // attempt cookie restore
    const tryCookieRestore = ( config && config.restore_from_cookie) ? config.restore_from_cookie : true
    if( tryCookieRestore ){
      try{  
        const vehicle = await resolve_vehicle_cookie()
        Store.dispatch(restore_vehicle(vehicle))
        sethasVehicle(true)
      }catch(e){
        sethasVehicle(false)
        if( window.DEBUG ){ console.error(e) }
      }finally{
        setInitialized(true)
      }
    }
  }
  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    restoreFromCookie()
  }, [])

  if( !initialized ){
    return <div></div>
  }else if( hasVehicle ){
      return <Redirect to={{ pathname: visualizer.path || '/visualizer' }} />
  }else{
    return <Redirect to={{ pathname: vehicle_selection_form.path || '/select' }} />
  }
}

/**
 *  Single Page Application with routes for the vehicle selection form (/select)
 *  and the vehicle visualizer (/visualizer)
 */


const RouterTypes = {
  'HASH': HashRouter,
  'BROWSER': BrowserRouter
}

export function VeloxVisualizerApp({
  config,
}: VisualizerAppProps){
  const CONFIG = {...DEFAULT_CONFIG, config}
  const Router = CONFIG.router ? (RouterTypes[ upper(CONFIG.router.type) ] || RouterTypes.BROWSER) : RouterTypes.BROWSER
  const routerConfig = CONFIG.router ? CONFIG.router.config : {}

  const ConfiguredVehicleSelectonFormModule = (props) => (
    <VehicleSelectonFormModule
      {...CONFIG.vehicle_selection_form}
      onSubmit={x => {
        const onSubmit = CONFIG.vehicle_selection_form.onSubmit
        if( onSubmit && _.isFunction(onSubmit) ){
          onSubmit(x)
        }
        setTimeout(()=>{
          window.location.assign(CONFIG.vehicle_selection_form.path)
        }, 300)
      }}
      config={config}
    />
  )
  
  const ConfiguredVisualizerModule = (props) => (
    <VisualizerModule
      {...CONFIG.visualizer}
      config={CONFIG.visualizer}
    />
  )

  return (
    <VeloxVisualizerContext.Provider value={CONFIG}>
      <Provider store={Store}>
        <Router {...routerConfig}>
          <div>
            <Route path='/'>
              <RootApp config={CONFIG} />
            </Route>
            <Route exact path={ CONFIG.vehicle_selection_form.path } component={ ConfiguredVehicleSelectonFormModule } />
            <Route exact path={ CONFIG.visualizer.path }  component={ ConfiguredVisualizerModule } />
          </div>
        </Router>
      </Provider>
    </VeloxVisualizerContext.Provider>
  )
}

export default VeloxVisualizerApp
