import _ from 'lodash'

import initial_state from './initial_state'
import { VEHICLE_SELECT_RESET, LOAD_RESULTS, SELECT_COLOR, SELECT_WHEEL, RESULTS_IS_LOADING, RESULTS_FILTER_ADD, RESULTS_FILTER_REMOVE, RESULTS_FILTER_RESET, RESULTS_SORT, RESTORE_VEHICLE_SELECTION  } from '../../actions'
import { lower, plural, log, Vehicle, vehicle_changed, vehicle_has_colors, VEHICLE_SELECTION_DROPDOWNS, SHOW_DETAILS  } from 'velox-visualizer-tools'
const responses = {}

responses[ LOAD_RESULTS ] = (state, action) => {
	log.debug('[ LOAD_RESULTS ] Visualizer/vehicle/reducers.js')
	var { vehicle, wheels } = action.data
	var new_vehicle = new Vehicle(vehicle)
	if( new_vehicle.is_different_from(state) && new_vehicle.has_colors() ){
		new_vehicle.current_color = new_vehicle.image.colors[0]
		new_vehicle.set_color( new_vehicle.current_color )
	}else if( new_vehicle.has_colors() ){
		new_vehicle.current_color = state.current_color
	}
	return new_vehicle
}

responses[ VEHICLE_SELECT_RESET ] = (state, action) => {
	return initial_state
}

responses[ SELECT_COLOR ] = (state, action) => {
	let color = action.data
	let vehicle = _.assign({}, state, {
		current_color: color
	})
	vehicle.image.url = color.image
	var new_vehicle = new Vehicle(vehicle)
	return new_vehicle
}

export function reducer(state=initial_state, action){
	if( responses[action.type] ){
		return responses[action.type](state, action)
	}else{
		return state
	}
}

export default reducer
