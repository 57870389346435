export default {
	current_wheel: null,
	wheels: [],
	filters: [],
	filters_map: {},
	sort: {},
	show_details: false,
	showCost: false,
	is_modal_open: {
		change_vehicle: false
	}
}