import $ from 'jquery'
import React from 'react'
import ReactDOM from 'react-dom'
import { is_backspace_key, is_character_key, slugify } from '../utils'
import { history } from '../history'

import { InlineEditable} from './InlineEditable'

export const OrderHistoryTable = (function(){

	const API_ENDPOINT = '/api/orders/table'

	var order_table = {
		filters: {
			q: null,
			status: null,
			page: null
		}
	}

	function handle_page_link_click(e){
		e.preventDefault()
		e.stopPropagation()
		var $this = $(this),
			link = $this.attr('href')
		if( link ){
			var page = $this.data('page')
			order_table.filters.page = page
			order_table.filter()
		}
	}
	order_table.get_querystring = function(){
		var querystring = []
		Object.keys(order_table.filters).forEach( key => {
			let value = order_table.filters[key]
			if( value && value.length ){
				querystring.push(key + '=' + order_table.filters[key])
			}
		})
		if( querystring.length ){
			querystring = querystring.join('&')
			querystring = '?' + querystring
			return querystring
		}else{
			return ''
		}
	}
	order_table.filter = function(){
		let $order_table_content = this.$order_table_content,
			filters = this.filters
		//console.log(filters)
		$.get(API_ENDPOINT, filters, function(html){
			$order_table_content.html(html)
			initialize_inline_editable()
		})
		history.push({
			pathname: window.location.pathname,
			search: order_table.get_querystring()
		})
	}
	order_table.initialize = function(){
		var $order_search = $('#order-search'),
			$order_status = $('.order-history #select-status'),
			$order_table_content = $('.order-history .content'),
			$clear_filters_link = $('.link-clear-filters'),
			$pagination = $('.order-history .pagination'),
			$page_items = $pagination.find('.page-item'),
			$page_links = $pagination.find('.page-link')

		this.$order_search = $order_search
		this.$order_status = $order_status
		this.$order_table_content = $order_table_content
		this.$clear_filters_link = $clear_filters_link

		$order_search.keyup(function order_search_onchange(e){
			var value = this.value
			order_table.filters.q = value
			order_table.filters.page = null
			if( is_character_key(e.keyCode) || is_backspace_key(e.keyCode) ){
				order_table.filter()
			}
		})

		$order_status.change(function order_status_onchange(e){
			var value = this.value
			order_table.filters.status = value
			order_table.filters.page = null
			order_table.filter()
		})

		$clear_filters_link.click(function clear_filters(e){
			e.preventDefault()
			order_table.filters = { q: null, status: null }
			$order_search.val('')
			$order_status.val('')
			order_table.filter()
		})

		$page_links.click( handle_page_link_click )
		$page_items.click( handle_page_link_click )

		var query = window.location.search.slice(1).split('&')
		query.forEach(q => {
			q = q.split('=')
			order_table.filters[ q[0] ] = q[1]
		})
		//console.log(order_table.filters)
	}

	return order_table
})()

export default OrderHistoryTable

export function initialize_inline_editable(){
	console.log('intiialize inline editable')
	$('.inline-editable').each(function(){
		var $el = $(this),
			props = $el.data()
		if( props.field === 'po' ){
			props.onSave = function(value, response){
				let order = response,
					status = slugify(order.status),
					has_po = order.po ? 'has-po' : 'no-po'
				//console.log(order)
				$el.parents('tr.order-history-table-row').each(function(){
					let $row = $(this),
						row_classes = $row[0].className,
						current_status_class = null,
						current_po_class = null

					if( row_classes && row_classes.length ){
						row_classes = row_classes.split(' ')
						for( var i=0; i < row_classes.length; i++ ){
							let c = row_classes[i]
							if( c.includes('row-status') ){
								current_status_class = c
							}
							if( c.includes('has-po') || c.includes('no-po') ){
								current_po_class = c
							}
						}
						if( current_status_class ){
							$row.removeClass(current_status_class).addClass('row-status-' + status)
							$row.removeClass(current_po_class).addClass('row-' + has_po)
						}
					}
					$row.find('.status').each(function(){
						let $this = $(this)
						$this.removeClass().addClass("status " + status)
						$this.html(order.status)
					})
					$row.find('.po').each(function(){
						let $this = $(this)
						$this.removeClass('has-po').removeClass('no-po').addClass(has_po)
					})
				})
			}
		}
		ReactDOM.render(<InlineEditable {...props} />, $el[0])
	})
}
