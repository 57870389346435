import React, { Component, useState, useEffect, useContext } from 'react'

import { VisualizerVehicleResponsive, VehicleColorOptions } from '../vehicle'
import WheelResults from '../results/wheels'
import WheelDetails from '../wheel_details'
import actions from '../../action_creators'

import './visualizer_main.scss'
import { VeloxVisualizerContext } from '../../context'

const { SELECT_COLOR, SELECT_WHEEL } = actions

export function VisualizerMain(props){
	const { vehicle, results, series_details, cart, wheels, wheels_filtered, onCheckout, sort_options, sort_directions, select_color, set_quantity, checkout_button_text, show_share, min_qty, max_qty, is_stocking } = props

	const { terms, showCostButton } = useContext(VeloxVisualizerContext)
	const { partner } = terms

	const [ showCost, setCostVisibility ] = useState(false)

	function className(){
		let component_classes = ['velox-visualizer-main']
		// Add class for details from the show_details prop (received from state)
		component_classes.push( vehicle.should_show() ? 'has-vehicle' : 'has-no-vehicle')
		let has_results = results && results.has_results
		component_classes.push( has_results ? 'has-results' : 'has-no-results')
		return component_classes.join(' ')
	}

	return (
		<div className={className()}>
			{ (vehicle.has_image() && !!results.has_results) && (
				<VehicleColorOptions
					colors={vehicle.image.colors}
					current_color={vehicle.current_color}
					onSelectColor={ select_color }
				/>
			)}
			{ ( vehicle.should_show() && !!results && !!results.has_results ) && (
				<VisualizerVehicleResponsive { ...props }
					wheel={cart.wheel}
					select_color={ select_color }
				/>
			)}
			<WheelResults
				{ ...props }
				viewed={cart.viewed}
				show_share={show_share}
				showToggleCost={partner == 'Bsro'}
				showCostButton={showCostButton}
				showCost={showCost}
				setCostVisibility={setCostVisibility}
			/>
			{ !!cart.wheel && <WheelDetails
				wheel={cart.wheel}
				vehicle={vehicle}
				qty={cart.qty}
				min_qty={min_qty}
				max_qty={max_qty}
				set_quantity={set_quantity}
				selected={cart.selected}
				series_details={series_details}
				onCheckout={onCheckout}
				show_share={show_share}
				checkout_button_text={checkout_button_text}
				is_stocking={is_stocking}
				showCost={showCost}
				setCostVisibility={setCostVisibility}
			/>}
		</div>
	)
}
export default VisualizerMain
