import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { ModuleConfigurableComponent } from '../../common/module_component'
import { VehicleSelectionFormDropdown } from '../vehicle_select_dropdown'

import { initialize, select } from '../action_creators'

import './vehicle_selection_form.scss'

/** Vehicle Selection Form Module. Inherits from ModuleConfigurableComponent.
  *
  */
export class VehicleSelectionForm extends ModuleConfigurableComponent{
	/*
	static propTypes = {
		className: PropTypes.string,
		config: PropTypes.shape({
			title: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
			button_text: PropTypes.string,
			layout: PropTypes.oneOf(['grid', 'row', 'column', 'default'])
		}),
		onSubmit: PropTypes.func.isRequired,
		vehicle: PropTypes.object,
		title: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
		form: PropTypes.shape({
			submitting: PropTypes.bool,
			is_loading: PropTypes.shape({ makes: PropTypes.bool, models: PropTypes.bool, years: PropTypes.bool, sizes: PropTypes.bool }),
			selected: PropTypes.shape({ makes: PropTypes.bool, models: PropTypes.bool, years: PropTypes.bool, sizes: PropTypes.bool }),
			makes: PropTypes.array,
			models: PropTypes.array,
			years: PropTypes.array,
			sizes: PropTypes.array
		}),
		layout: PropTypes.oneOf(['grid', 'row', 'column', 'default'])
	}
	*/
	componentDidMount() {
		super.componentDidMount()
		try{
			this.props.dispatch(initialize(this.props.config))
		}catch(e){
			console.error(e)
		}
	}
	render(){
		const { form, vehicle, title, button_text } = this.props
		var config = this.props.config || {}
		const { submitting, is_loading, selected, makes, models, years, sizes, is_open } = form
		const form_button_text =  button_text || (config && config.button_text) || 'Submit'
		const form_title =  title || (config && config.title) || 'Find Wheels By Vehicle'
		var form_ready = this.is_form_ready()
		return (
			<form className={ this.className() } onSubmit={this.submit}>
				{ !!form_title && (<h1 className='title'>{ form_title }</h1>)}
				<VehicleSelectionFormDropdown
		        	name="make"
		        	options={makes}
		        	loading={is_loading.makes}
		        	selected={selected.make}
		        	onChange={ x => this.select('make', x) }
		        	set_ref={ e => { this.dropdowns['make'] = e }}
		        	dropdowns={this.dropdowns}
		        	open={ is_open['makes'] }
	        	/>
		         <VehicleSelectionFormDropdown
		         	name="model"
		         	options={models}
		         	loading={is_loading.models}
		         	selected={selected.model}
		         	onChange={ x => this.select('model', x) }
		         	set_ref={ e => { this.dropdowns['model'] = e }}
		         	dropdowns={this.dropdowns}
		         	open={ is_open['models'] }
	         	/>
		        <VehicleSelectionFormDropdown
		          	name="year"
		          	options={years}
		          	loading={is_loading.years}
		          	selected={selected.year}
		          	onChange={ x => this.select('year', x) }
		          	set_ref={ e => { this.dropdowns['year'] = e }}
		          	dropdowns={this.dropdowns}
		          	open={ is_open['years'] }
	          	/>
		        <VehicleSelectionFormDropdown
		           	name="size"
		           	options={sizes}
		           	loading={is_loading.sizes}
		           	selected={selected.size}
		           	onChange={ x => this.select('size', x) }
		           	set_ref={ e => { this.dropdowns['size'] = e }}
		           	dropdowns={this.dropdowns}
		           	open={ is_open['sizes'] }
	           	/>
				<button type='submit' className={'btn submit ' + ( form_ready ? '' : 'disabled')} disabled={!form_ready}>
					{ submitting ? 'Submitting...' : form_button_text }
				</button>
			</form>
		)
	}

	constructor(){
		super('velox-vehicle-selection-form"')
	}
	dropdowns = {}
	LAYOUT_OPTIONS = ['grid', 'row', 'column', 'default']
	className = () => {
		var config = this.props.config || {}
		var c  = ['velox-vehicle-selection-form']
		var layout = this.props.layout || (this.props.config && this.props.config.layout) || 'default'
		if( this.props.config && this.props.config.className ){
			c.push(this.props.config.className)
		}
		if( this.props.className ){
			c.push(this.props.className)
		}
		if( layout && this.LAYOUT_OPTIONS.includes(layout) ){
			c.push('l-' + layout )
		}else if( !(c.includes('l-grid') || c.includes('l-row') || c.includes('l-column') || c.includes('l-default')) ){
			c.push('l-default')
		}

		var form_button_text =  this.props.button_text || (config && config.button_text)
		if( form_button_text === false ){
			c.push('no-button')
		}else{
			c.push('has-button')
		}
		return c.join(' ')
	}

	/**
	 * Dispatches a field selection event
	 * @param {string} field
	 * @param {string} value
	 * @public
	 */
	select = (field, value) => {
		this.props.dispatch(select(field, value))
	}
	/**
	 * Checks if form is ready for submission
	 * @public
	 */
	is_form_ready = () => {
		let { selected } = this.props.form
		return !!( selected.make && selected.model && selected.year && selected.size )
	}
	/**
	 * Handles form submission. Will pass { vehicle, wheels } to onSubmit handler
	 *
	 * @param {SyntheticEvent} event
	 * @public
	 */
	submit = (e) => {
		if(e){
			e.stopPropagation()
			e.preventDefault()
		}
		let { vehicle, results, form } = this.props

		var form_ready = this.is_form_ready()
		var results_loaded = this.props.results.is_loaded

		if( form_ready && results_loaded && _.isFunction(this.props.onSubmit) ){
			this.props.onSubmit && this.props.onSubmit({
				vehicle: vehicle,
				wheels: results.wheels
			})
		}else if( form_ready ){
			this.select( 'size', form.selected.size )
			this.submit_wait = this.submit_wait + this.submit_retry_interval
			if( this.submit_wait < this.max_submit_wait ){
				var { submit, submit_retry_interval } = this
				setTimeout( submit, submit_retry_interval*1000 )
			}else{
				// TODO: Provide a way to fix on error
				console.error('Error, form is not ready or no search results were found')
			}
		}
	}
}

export const ConnectedVehicleSelectionForm = connect( state => {
	return {
	    form: state.form_select,
	    vehicle: state.vehicle,
	    results: state.results
	}
})(VehicleSelectionForm)

export default ConnectedVehicleSelectionForm
