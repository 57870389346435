import React, { useState, useRef, useEffect } from "react"
import _, { initial } from "lodash"

import { Caret } from "../../common/icons"
import { upper, Collection } from 'velox-visualizer-tools'

export type FilterProps = {
	title: string;
	collection: Collection;
	field: string;
	appliedFilters: any[];
	textValue?: (initial: any) => string;
	initial_open?: boolean;
	valueType?: 'NUMBER' | 'STRING';
	onChange: (string, any) => any;
}

function convertValue(value: any, valueType: string){
	if( upper(valueType) === 'NUMBER' ){
		return Number(value)
	}else{
		return value
	}
}

export default function Filter({ title, initial_open, collection, field, appliedFilters, textValue, valueType, onChange }: FilterProps){
	const [ open, setOpen ] = useState(initial_open || false)
	const valueCount = useRef(null);

	const value_counts = collection.count(field)
    const values = _.keys(value_counts)
    values.sort( (a, b) => {
      if( value_counts[a] < value_counts[b] ){
        return 1
      }else if( value_counts[a] > value_counts[b] ){
        return -1
      }else{
        return 0
      }
    })

	useEffect(()=>{
		setOpen(open)
	}, [initial_open])

	function generateFilterItem(value: any){
		const name = `filter-${field}-${value}`
		const select_value = e => onChange(field, convertValue(value, valueType))
		const convertedValue : any = convertValue(value, valueType )
		// eslint-disable-next-line eqeqeq
		var is_value_checked : boolean = appliedFilters && appliedFilters.length && appliedFilters.includes(convertedValue)
		return (
			<li  className="accordion-item" key={name} onClick={ select_value }>
			<input
				type="checkbox"
				name={name}
				value={value}
				onChange={ select_value }
				onClick={ select_value }
				checked={ is_value_checked }
				className="checkbox-input"
			/>
			<label  htmlFor={name} className="vxviz-sidebar-label checkbox-label">
				<span className="accordion-item-value">
				{ textValue ? textValue(value) : value }
				<span className="count">&nbsp;({ value_counts[value] })</span>
				</span>
			</label>
			</li>
		)
	}

	return (
		<li className={'filter accordion-item' + ( open ? ' active' : '' )}>
			<div
				className="filter-name accordion-header"
				onClick={ () => setOpen(!open) }
			>
			{ title }
			<Caret direction={ open ? 'right' : 'down' } />
			</div>
			<ul className="filter-values-list accordion-menu">
				{values.map(value => generateFilterItem(value))}
			</ul>
		</li>
	)
}
