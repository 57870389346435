import _ from 'lodash'
import { Collection } from 'velox-visualizer-tools'

import initial_state from './initial_state'
import { RESULTS_FILTER_ADD, SET_QTY, SELECT_WHEEL, SELECT_WHEEL_OPTION, SHOW_DETAILS, LOAD_RESULTS } from '../../actions'

const responses = {}

responses[SELECT_WHEEL] = (state, action) => {
	let wheel = action.data
	var new_state = _.assign({}, state)

	let viewed_wheel_hash = {}
	viewed_wheel_hash[wheel.model + '|' + wheel.finish] = true

	new_state.wheel = wheel
	new_state.selected = wheel.sizes[0]
	new_state.viewed = _.assign(new_state.viewed, viewed_wheel_hash)

	return new_state
}

responses[ LOAD_RESULTS ] = (state, action) => {
	return _.assign({}, state, {
		wheel: null,
		selected: {},
	})
}
responses[RESULTS_FILTER_ADD] = (state, action) => {
	return _.assign({}, state, {
		wheel: null,
		selected: {},
	})
}

responses[SET_QTY] = (state, action) => {
	return _.assign({}, state, {
		qty: action.data
	})
}

responses[SELECT_WHEEL_OPTION] = (state, action) => {
	var o = {}
	let { item, option } = action.data
	o[item] = option
	var selected = _.assign({}, state.selected, o)
	var selected_wheel = _.assign({}, state.wheel, option)
	return _.assign({}, state, {
		selected: selected,
		wheel: selected_wheel
	})
}

responses[SHOW_DETAILS] = (state, action) => {
	if( action.data === false ){
		return _.assign({}, state, {
			wheel: null
		})
	}else{
		return state
	}
}

export function reducer(state=initial_state, action){
	if( responses[action.type] ){
		return responses[action.type](state, action)
	}else{
		return state
	}
}

export default reducer
