import axios from "axios"

import Pkg from "../../../../package.json"

// API error handlers
const on_unauthorized = e => {
	/*
	if( window.location.host !== "localhost:3000" ){
		if( e.status === 401 ){
			window.location = "/login"
		}
	}
	*/
	if( e.status === 401 ){
		console.error("Unauthorized")
	}
}

// Main Function
export function VeloxAPI(token, API_URL=Pkg.api.url){

	function API(url) {
	    if(url){
	        return API_URL + String(url)
	    }else{
	        return API_URL
	    }
	}
	const headers = (
		token ? {
		  "Authorization": "Token " + token,
		  "x-api-key": token
		} : {}
	)

	const request = axios.create({
	  baseURL: API_URL,
	  timeout: 10000,
	  headers: headers
	});

	return {
		Vehicles: {
			Get: {
				Makes: (query) =>  {
					return request.get("/vehicles/makes")
						.then( res => res.data )
				},
				Models: (query) =>  {
					return request.get("/vehicles/models", {
						params: { make: query.make  }
					}).then( res => res.data )
				},
				Years: (query) =>  {
					return request.get("/vehicles/years", {
						params: {
							make: query.make,
							model: query.model
						}
					}).then( res => res.data )
				},
				Sizes: (query) =>  {
					return request.get("/vehicles/sizes", {
						params: {
							make: query.make,
							model: query.model,
							year: query.year
						}
					}).then( res => res.data )
				},
				Wheels: (query) => {
					return request.get("/vehicles/wheels", {
						params: {
							make: query.make,
							model: query.model,
							year: query.year,
							size: query.diameter || query.size
						}
					}).then( res => res.data )
				}
			}
		},
		Wheels: {
			Get: {
				Series: () => {
					return request.get("/wheels/series")
						.then( res => res.data )
						.catch( on_unauthorized )
				}
			}
		}
	}
}


export default VeloxAPI
