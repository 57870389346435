import React, { Component } from 'react'
import _ from 'lodash'

import { DropdownList } from 'react-widgets'
import { LoadingDots } from './loading'

/*
export class Dropdown extends Component{
	render(){
		let props = _.assign({}, this.props)
		delete props.set_ref
		delete props.loading
		return (
			<div className={ 'dropdown ' + (this.props.className ? this.props.className : '') } >
				<DropdownList {...props} ref={this.props.set_ref} busy={this.props.loading} />
			</div>
		)
	}
}
*/

export function Dropdown(props){
	let dropdown_props = _.assign({}, props)
	delete dropdown_props.set_ref
	delete dropdown_props.loading
	delete dropdown_props.caseSensitive
	delete dropdown_props.valueField

	return (
		<div className={ 'dropdown ' + (props.className ? props.className : '') }>
			<DropdownList {...dropdown_props} ref={props.set_ref} busy={props.loading} />
		</div>
	)
}

export default Dropdown

export class QtyDropdown extends Component{
	get_options(){
		let max_qty = (this.props.max < 8) ? this.props.max : 8
		let min_qty = this.props.min || 1
		let arr = []
		for( var i=min_qty; i <= max_qty; i++ ){
			arr.push(i)
		}
		return arr
	}
	render(){
		let default_value = (this.props.max < 4) ? this.props.max : 4
		return (
			<div className="select-qty">
				<Dropdown
					data={ this.get_options() }
					valueField="qty"
					defaultValue={ default_value }
					className="dropdown-qty"
					onChange={ this.props.onChange }
				/>
			</div>
		)
	}
}
