import React, { MouseEventHandler } from 'react';
import { WheelImagePlaceholder } from '../common/placeholders';


export interface Image360ToolbarProps {
	rotateLeft: MouseEventHandler<HTMLButtonElement>;
	rotateRight: MouseEventHandler<HTMLButtonElement>;
}

export function Image360Toolbar({ rotateLeft, rotateRight }: Image360ToolbarProps) {
	return (
		<aside
			className={"image-360-toolbar"}
		>
			<div className="image-360-toolbar-wrapper">
				<button className="image-360-toolbar-item rotate-step-button" onClick={rotateLeft}>
					<img src="https://cdn.veloxwheels.com/product-images/360/left-arrow.png" />
				</button>
				<div className="image-360-toolbar-item drag-to-spin">Drag to Spin</div>
				<button className="image-360-toolbar-item rotate-step-button" onClick={rotateRight}>
					<img src="https://cdn.veloxwheels.com/product-images/360/right-arrow.png" />
				</button>
			</div>
		</aside>
	);
}

export function Image360Loading({ percent }: { percent: number; }) {
	return (
		<div className="loading-placeholder">
			<WheelImagePlaceholder />
			<div className="loading-placeholder-wrapper">
				<div className="loading-placeholder-text">
					Loading...
				</div>
				<div className="loading-bar">
					<div className="loading-bar-progress" style={{ width: String(percent) + '%' }}>
						<span className="loading-bar-progress-text">{percent}%</span>
					</div>
				</div>
			</div>
		</div>
	);
}
