import { createBrowserHistory } from 'history'

export const history = createBrowserHistory();

history.listen((location, action) => {
	//console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`)
  	//console.log(`The last navigation action was ${action}`)
  	if( location.pathname == '/' ){
  		window.location = '/';
  	}
})

export default history
