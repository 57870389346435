import React, { Component, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { Remove, Filter, Sort, Dropdown, FilterTag } from '../../../common'

import { PROP_TYPES } from 'velox-visualizer-tools'
import actions from '../../../action_creators'

import './results_toolbar.css'

export type SortType = {
	name: 'Ascending' | 'Descending';
	value: 'asc' | 'desc';
}
export type SortField = {
	name: 'Name' | 'Series' | 'Price';
	field: 'model' | 'series' | 'current_price';
}

export function WheelResultsToolbar(props){
	let { wheels, wheels_all, vehicle, filters, sort_options, sort_directions, dispatch, showCostButton, showCost, setCostVisibility } = props
	let vehicle_description = `${vehicle.year || ''} ${vehicle.make || ''} ${vehicle.model || ''} ${vehicle.size || ''}`
	let has_filters = Object.keys(filters).length > 0

	const [ sortField, setSortField ] = useState({
		name: 'Price',
		field: 'current_price'
	})
	const [ sortType, setSortType ] = useState('asc')

	const reset_filters = () => dispatch(actions.RESULTS_FILTER_RESET())
	const remove_filter = (filter) => dispatch(actions.RESULTS_FILTER_REMOVE(filter))
	const handle_sort = (sort_field, sort_type) => {
		let sort = {}
		sort[sort_field] = sort_type
		dispatch(actions.RESULTS_SORT(sort))
	}

	return (
		<ul className="toolbar">
			<li className={'applied-filters ' + (has_filters ? 'has-filters' : 'no-filters')}>
				<Filter />
				<ul className="tags tags-filters">
					{ (vehicle.make && vehicle.model && vehicle.year && vehicle.size) && (
						<FilterTag filter={{ 'Size': vehicle.size }} remove={remove_filter} />
					)}
					{ filters.map((filter, i) => <FilterTag filter={filter} remove={ remove_filter } key={'tags-filters-' + i} /> ) }
					<li className="clear-filters" onClick={ reset_filters }>Clear filters</li>
				</ul>
			</li>
			<li className="sort-options">
				<Sort />
				<Dropdown
					data={ sort_options }
					textField="name"
					valueField="field"
					defaultValue={ sort_options[0] }
					className="sort-field-dropdown"
					onChange={ (sort_field: SortField) =>  {
						setSortField(sort_field)
						handle_sort( sort_field.field, sortType )
					}}
				/>
				<Dropdown
					data={ sort_directions }
					textField="name"
					valueField="value"
					defaultValue={ sort_directions[0] }
					className="sort-type-dropdown"
					onChange={ (sort_type : SortType)  => {
						setSortType(sort_type.value)
						handle_sort(sortField.field, sort_type.value )
					}}
				/>
				{ !!showCostButton && (
					<button className={"show-cost-button btn " + (showCost ? 'show-' : 'hide-') + 'cost ' + (showCost ? 'blue' : 'green')} onClick={() => setCostVisibility(!showCost) }>
						{ showCost ? 'Hide Cost' : 'Show Cost' }
					</button>
				)}
			</li>
		</ul>
	)
}
export default WheelResultsToolbar
