import React, { Component } from "react";
import _ from "lodash";
import {
  proper,
  upper,
  css_class,
  wheel_offset,
  currency,
} from "velox-visualizer-tools";

export class Wheel {
  constructor(wheel) {
    this.wheel = wheel;
  }
  size = () => this.wheel.diameter + "x" + this.wheel.width;
  offset = () => wheel_offset(this.wheel.offset);
  finish = () => proper(this.wheel.finish_description);
  price = () => currency(this.wheel.current_price);
  weight = () => this.wheel.weight;
  construction = () => this.wheel.material;
  pcd = () => this.wheel.pcd;
  is_all_season = () =>
    this.wheel.is_all_season || this.wheel.is_snow ? "Yes" : "No";
}

export class WheelSpec extends Component {
  render() {
    let { label, label_text } = this.props;
    if (!label_text) {
      label_text = css_class(label);
    }
    if (label === "pcd") {
      label = upper(label);
    } else {
      label = proper(label);
    }
    return (
      <li className={"wheel-spec wheel-" + label_text}>
        <label className="label">{label}:</label>
        <span className="value">{this.props.children}</span>
      </li>
    );
  }
}

export class WheelSpecs extends Component {
  render() {
    let { specs, wheel, option, vehicle } = this.props;

    let current_wheel = _.assign({}, wheel, option);
    let wheel_model = new Wheel(current_wheel);

    let visible_specs = _.filter(specs, (field) => {
      return (
        !!wheel[field] ||
        (wheel_model[field] && !!wheel_model[field]()) ||
        field == "suggested_tire_size"
      );
    });
    return (
      <ul className="specs">
        {visible_specs.map((field) => {
          var value;
          if (field == "suggested_tire_size") {
            value = vehicle.tire_size;
          } else if (wheel_model[field]) {
            value = wheel_model[field]();
          } else {
            value = wheel[field];
          }
          return (
            <WheelSpec label={field} key={"wheel-spec-" + field}>
              {value}
            </WheelSpec>
          );
        })}
      </ul>
    );
  }
}

export default WheelSpecs;
