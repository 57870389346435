import React, { Component } from 'react'
import { connect, Provider } from 'react-redux'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import { ModuleConfigurableComponent } from '../common/module_component'
import { RootLoading, Cancel } from '../common'
import { VisualizerSidebar } from './sidebar'
import { VisualizerMain } from './main'
import { ConnectedVehicleSelectionForm } from '../vehicle_select'

import { get_grouped_wheels, get_filtered_wheel_collection, get_wheels_collection } from './selectors'
import { SORT_OPTIONS, SORT_DIRECTIONS } from '../config'

import actions, { toggle_filter, load_results, restore_vehicle } from '../action_creators'
import { select } from '../vehicle_select/action_creators'
import { Cookies, PROP_TYPES } from 'velox-visualizer-tools'

import './visualizer.scss'

/**
 *	Visualizer Results Page. Displays the vehicle with wheel results and a sidebar for filters.
 *	Clicking on a wheel will show it on the vehicle as well as open a wheel_details display
 *	for item confirmation and checkout.
 */
export class Visualizer extends ModuleConfigurableComponent{
	/*
	static propTypes = {
		show_details: PropTypes.bool,
		dispatch: PropTypes.func.isRequired,
		vehicle: PropTypes.shape({
			year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			make: PropTypes.string,
			model: PropTypes.string,
			diameter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			available_diameters: PropTypes.arrayOf(PropTypes.number),
			image: PropTypes.shape({
				vif: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
				wheel_positions: PropTypes.shape({
					front: PropTypes.shape({
						x: PropTypes.number,
						y: PropTypes.number,
						radius: PropTypes.number,
						width: PropTypes.number
					}),
					rear: PropTypes.shape({
						x: PropTypes.number,
						y: PropTypes.number,
						radius: PropTypes.number,
						width: PropTypes.number
					})
				}),
				colors: PropTypes.array
			})
		}),		results: PropTypes.shape({
			wheels: PropTypes.array.isRequired,
			wheels_orig: PropTypes.array,
			show_details: PropTypes.bool,
			filters: PropTypes.array,
			filters_map: PropTypes.object,
			current_wheel: PropTypes.object,
			sort: PropTypes.object,
			is_loaded: PropTypes.bool,
			is_modal_open: PropTypes.object
		}),
		onSelectVehicle: PropTypes.func,
		onCheckout: PropTypes.func,
		onSelectQty: PropTypes.func,
		show_share: PropTypes.bool,
		checkout_button_text: PropTypes.string,
		min_qty: PropTypes.number,
		max_qty: PropTypes.number,
		is_stocking: PropTypes.bool,
		config: PropTypes.shape({
			restore_from_cookie: PropTypes.bool,
			checkout_button_text: PropTypes.string,
			onSelectVehicle: PropTypes.func,
			onCheckout: PropTypes.func,
			show_share: PropTypes.bool,
			min_qty: PropTypes.number,
			max_qty: PropTypes.number,
			is_stocking: PropTypes.bool
		})
	}
	*/
	static load_results = (dispatch, results) => {
		return dispatch(load_results(results));
	}
	constructor(){
		super('velox-vehicle-visualizer')
	}
	className = () => {
		let component_classes = this.get_classNames()
		// Add class for details from the show_details prop (received from state)
		component_classes.push( this.props.show_details ? 'is-details-open' : 'is-not-details-open')
		let classes = component_classes.join(' ')
		return classes
	}
	change_vehicle = () => this.props.dispatch(actions.MODAL_TOGGLE({
		modal: 'change_vehicle',
		open: true
	}))
	handle_filter =  (filter, value) => this.props.dispatch(toggle_filter(filter, value))
	set_quantity = (qty) => {
		var on_select_qty = this.props.onSelectQty || ( this.props.config && this.props.config.onSelectQty )
		if( on_select_qty ){
			on_select_qty(qty)
		}
		this.props.dispatch(actions.SET_QTY(qty))
	}
	select_size = (value) => {
		this.props.dispatch(select('size', value))
	}
	select_color = (color) => this.props.dispatch(actions.SELECT_COLOR(color))
	close_modal = () => this.props.dispatch(actions.MODAL_TOGGLE({ modal: 'change_vehicle', open: false }))
	select_vehicle = (results) => {
		//console.log('Vehicle', results.vehicle)
		var on_select_vehicle = this.props.onSelectVehicle || ( this.props.config && this.props.config.onSelectVehicle )
		if( on_select_vehicle ){
			on_select_vehicle(results)
		}
		this.close_modal()
	}
	checkout = (x) => {
		// Save the ordered wheel
		var restore_from_cookie = ( this.props.config && this.props.config.restore_from_cookie) ? this.props.config.restore_from_cookie : true
		if( restore_from_cookie ){
			Cookies.SelectedWheelCookie.set( x.wheel )
			Cookies.SelectedQtyCookie.set( x.qty )
		}
		if( this.props.onCheckout ){
			return this.props.onCheckout(x)
		}else if( this.props.config && this.props.config.onCheckout ){
			return this.props.config.onCheckout(x)
		}else{
			console.log('[Visualizer]\tCheckout called.', x)
		}
	}
	componentDidMount(){
		Modal.setAppElement('.velox-vehicle-visualizer')
	}
	render(){
		var { vehicle, results, checkout_button_text, show_share, is_stocking } = this.props
		var config = this.props.config || {}
		var is_vehicle_ok = vehicle && vehicle.is_ok && results.is_loaded
		if( vehicle && vehicle.is_ok && results ){
			return (
				<div className={ this.className() }>
					<VisualizerSidebar { ...this.props }
						handle_filter={this.handle_filter}
						change_vehicle={this.change_vehicle}
						select_size={this.select_size}
						select_color={this.select_color}
					/>
					<VisualizerMain {...this.props}
						is_stocking={ this.props.is_stocking || config.is_stocking }
						checkout_button_text={ checkout_button_text || config.checkout_button_text}
						onCheckout={this.checkout}
						sort_options={SORT_OPTIONS}
						sort_directions={SORT_DIRECTIONS}
						set_quantity={this.set_quantity}
						select_color={this.select_color}
						show_share={ this.props.show_share || config.show_share }
						min_qty={ this.props.min_qty || config.min_qty }
						max_qty={ this.props.max_qty || config.max_qty }
					/>
					<Modal
						isOpen={ results.is_modal_open['change_vehicle'] }
						onRequestClose={this.close_modal}
						shouldCloseOnOverlayClick={true}
						contentLabel="Vehicle Selection"
						className="react-modal"
						overlayClassName="react-modal-wrapper forefront"
					>
						<button className="btn modal-close" onClick={this.close_modal}>
							<Cancel  />
						</button>
						<ConnectedVehicleSelectionForm
							{...this.props}
							onSubmit={this.select_vehicle}
							className="l-grid l-modal"
						/>
					</Modal>
				</div>
			)
		}else{
			return (
				<div className={ this.className() }>
					<RootLoading />
				</div>
			)
		}
	}
}


// Connected Visualizer component
export const ConnectedVisualizer = connect( state => {
	return {
	    form: state.form_select,
	    vehicle: state.vehicle,
	    results: state.results,
	    wheels: get_grouped_wheels(state),
	    wheels_collection: get_wheels_collection(state),
	    wheels_filtered: get_filtered_wheel_collection(state),
	    API: state.API,
	    show_details: state.results.show_details,
	    cart: state.cart,
		series_details: state.series
	}
})(Visualizer)

export default ConnectedVisualizer
