import React, { Component } from "react"
import PropTypes from "prop-types"

import { CONFIG, PROP_TYPES } from "velox-visualizer-tools"

import { Loading, LoadingGrid, LoadingSpin } from "../../../common/loading"
import { ImageIfExists } from "../../../common/image_if_exists"

const VEHICLE_IMAGE_FALLBACK = "https://cdn.veloxwheels.com/ui/vehicle-loading-placeholder.gif"

export function VehicleImage(props){
	return (
		<ImageIfExists
			className={"vehicle-image" + (props.className ? " " + props.className : "" )}
			alt={ props.alt || "vehicle image"}
			{...props}
		/>
	)
}

export default VehicleImage
