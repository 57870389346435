import _ from 'lodash'

import initial_state from './initial_state'
import { VEHICLE_SELECT_INITIALIZE, VEHICLE_SELECT_LOAD_OPTIONS, VEHICLE_SELECT_VALUE, RESTORE_VEHICLE_SELECTION, VEHICLE_SELECT_RESET } from '../actions'
import * as Tools from 'velox-visualizer-tools'

const { lower, plural, VEHICLE_SELECTION_DROPDOWNS } = Tools
const responses = {}

responses[ VEHICLE_SELECT_INITIALIZE ] = (state, action) => {
	return _.assign({}, state, {
		config: action.data
	})
}

// Reducer response to selecting a value from any dropdown
responses[ VEHICLE_SELECT_VALUE ] = (state, action) => {
	let { field, value } = action.data

	let new_state = _.assign({}, state)
	let next_dropdown = VEHICLE_SELECTION_DROPDOWNS.after(field)

	//console.log("Setting selected field=" + field + " to " + value)
	//console.log(action)
	new_state.selected[ field ] = value
	new_state.is_touched[ plural(field) ] = true
	new_state.is_open[ plural(field) ] = false
	new_state.is_loading[ plural(next_dropdown) ] = true

	if( VEHICLE_SELECTION_DROPDOWNS.isNotLast(next_dropdown) ){
		VEHICLE_SELECTION_DROPDOWNS.forEachAfter(field, ( next_dropdown ) => {
			new_state.selected[ next_dropdown ] = null
			new_state[ plural(next_dropdown) ] = []
			new_state.is_loading[ plural(next_dropdown) ] = false
			new_state.is_touched[ plural(next_dropdown) ] = false
		})
	}
	return new_state
}

responses[ RESTORE_VEHICLE_SELECTION ] = (state, action) => {
	let vehicle = action.data
	let new_state = _.assign({}, state)
	new_state.selected = vehicle
	new_state.is_touched = {
		makes: true,
		models: true,
		years: true,
		sizes: true
	}
	return new_state
}

// Reducer response to receiving AJAX options to load into dropdown
responses[ VEHICLE_SELECT_LOAD_OPTIONS ] = (state, action) => {
	let { field, options } = action.data
	if( _.isArray(options) ){
		let new_state = _.assign({}, state)
		new_state[ plural(field) ] = options
		new_state.is_loading[ plural(field) ] = false
		return new_state
	}else{
		console.error("Non-array received in VEHICLE_SELECT_LOAD_OPTIONS reducer", { field, options })
		return state
	}
}

// Reducer response to restore vehicle state from cookie
responses[ RESTORE_VEHICLE_SELECTION ] = (state, action) => {
	let vehicle = action.data
	var new_state = _.assign({}, state)
	new_state.selected = _.assign({}, new_state.selected, _.pick(vehicle, ['make', 'model', 'size', 'year']))
	return new_state
}

responses[ VEHICLE_SELECT_RESET ] = (state, action) => {
	return _.assign({}, initial_state, {
		makes: state.makes,
		selected: {
			make: null,
			model: null,
			year: null,
			size: null
		}
	})
}

export function reducer(state=initial_state, action){
	if( responses[action.type] ){
		return responses[action.type](state, action)
	}else{
		return state
	}
}

export default reducer
