import _ from 'lodash'
import { createSelector } from 'reselect'

import { Collection } from 'velox-visualizer-tools'
import { group_wheels, does_wheel_match_filter } from './results/utils'
import { SORT_FUNCTIONS } from '../config'

export const get_wheels = (state) => state.results.wheels
export const get_filters = (state) => state.results.filters
export const get_filters_map = (state) =>  state.results.filters_map
export const get_sort = (state) => state.results.sort

export const get_wheels_collection = createSelector(
	[ get_wheels, ],
	(wheels) => {
		return new Collection(wheels)
	}
)
export const get_grouped_wheels = createSelector(
	[ get_wheels, get_filters, get_filters_map, get_sort ],
	(wheels, filters, filters_map, sort) => {
		let filtered_wheels = _.filter(wheels, wheel => does_wheel_match_filter(wheel, filters, filters_map))
		filtered_wheels = new Collection(filtered_wheels).sort(sort).get()

		let sort_array = [ SORT_FUNCTIONS.default ]
		Object.keys(sort).forEach( k =>  sort_array.push( SORT_FUNCTIONS[k] ) )

		let grouped_wheels = group_wheels(filtered_wheels, sort=sort_array)
		return grouped_wheels
	}
)

export const get_filtered_wheel_collection = createSelector(
	[ get_wheels, get_filters, get_filters_map, get_sort ],
	(wheels, filters, filters_map, sort) => {
    	let filtered_wheels = _.filter(wheels, wheel => does_wheel_match_filter(wheel, filters, filters_map))
    	return new Collection(filtered_wheels).sort(sort)
	}
)
