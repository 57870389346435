import $ from 'jquery'
import _ from 'lodash'
import React from 'react'
import { SaveIcon, PencilIcon, LoadingDots, XIcon } from '../icons'

import './InlineEditable.scss'

export class InlineEditable extends React.Component{
	initial_state = {
		value: null,
		text_value: null,
		is_editing: false,
		is_saving: false
	}
	constructor(props){
		super(props)
		this.state = _.assign({}, this.initial_state)
		this.state.value = this.parse_value(props.value)
		if( props.value === 'None' ){
			this.state.value = ''
		}
		this.choices = []
		this.choice_hash_to_label = {}
		this.choice_hash_to_value = {}
		if( props.choices ){
			this.choices = props.choices.split(',')
			this.choices = this.choices.map(choice => {
				var value, label
				if( choice.includes('|') ){
					choice = choice.split('|')
					value = this.parse_value(choice[0])
					label = choice[1]
				}else{
					value = this.parse_value(choice)
					label = choice
				}
				this.choice_hash_to_label[value] = label
				this.choice_hash_to_value[label] = value
				return {'value': value, 'label': label }
			})
			if( !props.nullable && props.nullable !== "false" ){
				this.choices.unshift({ 'value': '', label: 'None' })
			}
		}
		this.state.text_value = this.get_text_value(this.state.value)
	}
	componentDidMount(){
		this.$element = $(this._element)
		this.$parent = this.$element.parent('.inline-editable')
		this.tabindex = this.$parent.attr('tabindex')
		this.$parent.keypress(this.handle_keypress.bind(this))
	}
	focus_next_tabindex = () => {
		var next_tabindex = Number(this.tabindex) + 1
		var $next_element = $(`[tabindex=${next_tabindex}]`)
		$next_element.focus()
	}
	handle_keypress = (e) => {
		if( e.charCode ===  9 || e.key === "Tab" || e.keyCode === 9 ){ // Tab
			this.save()
			this.focus_next_tabindex()
		}
		if( e.charCode ===  13 || e.key === "Enter" ){ // Enter
			if( this.state.is_editing ){
				this.save()
				this.focus_next_tabindex()
			}else{
				this.toggle_editing()
			}
		}
	}
	handle_keyup = (e) => {
		if( e.charCode ===  27 || e.key === "Escape" || e.keyCode === 27 ){ // Escape
			this.cancel_editing()
			this.$parent.focus()
		}
	}
	handle_change = (e) => {
		let { field, endpoint } = this.props
		this.setState({
			value: this.parse_value(e.target.value)
		})
	}
	parse_value = (value) => {
		if( value === 'None' || value === ''){ value = '' }
		if( value === 'True' || value === 'true' ){ value = true }
		if( value === 'False' || value === 'false' ){ value = false }
		return value
	}
	get_text_value = (value) => {
		if( value === "" ){ return "None" }
		return this.choice_hash_to_label[value] || this.state.value || "None"
	}
	save = (e) => {
		let self = this,
			value = this.state.value,
			text_value = value

		if( value === '' ){ value = null }
		let data = {[this.props.field]: value }

		this.setState({ is_saving: true })
		console.log('Saving...', data)

		if( this.props.type === "dropdown" ){
			text_value = this.get_text_value(value)
		}

		$.ajax({
			url: this.props.endpoint,
			data: JSON.stringify(data),
			type: 'PATCH',
			dataType: 'json',
			contentType: 'application/json',
			processData: false,
			success: function(response){
				self.setState({
					is_saving: false,
					is_editing: false,
					text_value: text_value
				})
				if( self.props.onSave ){ self.props.onSave(value, response) }
			}
		})
	}
	toggle_editing = (e) => {
		this.setState({ is_editing: true })
		this.$element.find('.input').focus()
	}
	cancel_editing = (e) => {
		this.setState({
			is_editing: false,
			value: this.state.text_value
		})
	}
	render(){
		let { value, text_value, is_editing, is_saving } = this.state
		let { textClassName, type, field, nullable } = this.props
		let input_type = type || "text",
			input_props = {
				type: input_type,
				name: field,
				onChange: this.handle_change,
				value: value,
				ref: e => { this.input = e }
				//onKeyPress: this.handle_keypress
			}
		var input = <input className="input form-control" {...input_props} />
		if( input_type === "dropdown" ){
			var choices = this.choices
			delete input_props.type
			input = (
				<select className="input custom-select" {...input_props}>
				{choices.map(choice => (<option key={field + '-option-' + choice.value} value={choice.value}>{choice.label}</option>))}
				</select>
			)
		}
		if( is_editing ){
			return (
				<div className="inline-editable-content is-input is-editing" onKeyUp={this.handle_keyup} onKeyPress={this.handle_keypress} ref={e => { this._element = e} }>
					<div className="input-group">
						{input}
						<div className="input-group-append">
							<button className="btn btn-primary btn-save" onClick={this.save}>
								{ !!is_saving ? (<LoadingDots />) : (<SaveIcon />)}
							</button>
							<button className="btn btn-light btn-cancel" onClick={this.cancel_editing}>
								<XIcon />
							</button>
						</div>
					</div>
				</div>
			)
		}else{
			return (
				<div className="inline-editable-content is-text is-not-editing" ref={e => { this._element = e} } onKeyUp={this.handle_keyup} onKeyPress={this.handle_keypress}>
					<span className={"text" + (textClassName ? ' ' + textClassName : '')}>
						{text_value}
					</span>
					<PencilIcon onClick={this.toggle_editing} />
				</div>
			)
		}
	}
}

export default InlineEditable

