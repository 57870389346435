import * as Images from './images'
import * as Loading from './loading'
import * as Icons from './icons'
import * as Dropdowns from './dropdowns'
import * as Buttons from './buttons'
import * as Tags from './tags'
import * as Price from './price'

export * from './images'
export * from './loading'
export * from './icons'
export * from './dropdowns'
export * from './buttons'
export * from './tags'
export * from './price'
export * from './utils'

export default {
	Images,
	Loading,
	Icons,
	Dropdowns,
	Buttons,
	Tags,
	Price,
}
