import React from 'react'

import { proper, css_class, currency } from 'velox-visualizer-tools'

import { WheelImage, PriceStyled, SeriesLogo } from '../../../common'
import { WheelResultOverviewProps, WheelResultProps } from '../../types'

import './wheel_result.scss'

export function WheelResultOverview({
	finish_description,
	model,
	series,
	is_promo_active,
	current_price,
	price,
	onClick
}: WheelResultOverviewProps){
	let finish_class = 'wheel-finish' + (finish_description && finish_description.length > 16 ? ' long' : ''),
		unit_price_class = 'wheel-price unit-price' + (is_promo_active ? ' is-discount' : ' is-not-discount')
	return (
		<section className='wheel-result-overview'>
			<div className='wheel-description' onClick={ onClick }>
				<div className='wheel-main'>
					<h3 className='wheel-model'>{ model }</h3>
					<div className={ finish_class }>{ proper(finish_description) }</div>
				</div>
				<div className={ unit_price_class }>
					{ !!is_promo_active && (
						<div className='discount-price current-price'>
							<PriceStyled value={currency(current_price) } />
							<span className='unit'>/wheel</span>
						</div>
					)}
					{ !!is_promo_active && (
						<div className='original-price'>
							<span className='note'>was</span>
							<span className="strike"><PriceStyled value={ currency(price) } /></span>
						</div>
					)}
					{ !is_promo_active && (
						<div className='original-price current-price'>
							<PriceStyled value={ currency(current_price) } />
						</div>
					)}
				</div>
			</div>

		</section>
	)
}

export const WheelResultActions = ({ onClick }) => {
	return (
		<section className="wheel-actions">
			<div className="wheel-actions-buttons">
				<button className="btn-details btn sm" onClick={ onClick }>Details</button>
			</div>
		</section>
	)
}

export const PremiumLabel = ({ visible }: { visible: boolean }) => (
	<div className={"premium-label" + (visible ? ' is-visible' : '')}><img src="https://cdn.veloxwheel.com/ui/premium-ribbon.png" alt="Premium"/></div>
)

export function WheelResult({
	wheel,
	className,
	viewed,
	onViewDetails
}: WheelResultProps){

	const isPremium = wheel.brand.includes('6061')

	const component_classes = ['wheel-result']
	component_classes.push(viewed ? 'is-viewed' : 'is-not-loaded')
	if( className ){ component_classes.push(className) }
	className = component_classes.join(' ')

	function viewDetails(){
		if( onViewDetails ){ onViewDetails(wheel) }
		window.scrollTo(0, 0)
	}

	return (
		<div className={className}>
			<div className='wrapper'>
				<SeriesLogo series={wheel.series} />
				<PremiumLabel visible={isPremium}/>
				<WheelImage url={wheel.images.small} onClick={viewDetails } />
				<div className='wrapper-side wrapper'>
					<WheelResultOverview {...wheel} onClick={viewDetails} />
					<WheelResultActions onClick={viewDetails} />
				</div>
			</div>
		</div>
	)
}

export default WheelResult