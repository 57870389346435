import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import VisualizerVehicle from './vehicle'

/**
 * Responsive Visualizer Vehicle that will scale depending on window size
 */
export function VisualizerVehicleResponsive(props){

	const [zoom, setZoom] = useState(false)
	const [hover, setHover] = useState(false)
	const [maxWidth, setMaxWidth] = useState(800)
	const [loaded, setLoaded] = useState(false)

	const refs = {}
	const set_ref = (label, e) =>{ refs[label] = e }
	const MAX_WIDTH = 800
	
	function onWindowResize(){
		const vehicleWrapper = refs.vehicle_wrapper
		const vehicleImage = refs.visualizer_vehicle 

		if( vehicleWrapper ){
			const visualizerVehicleWidth = vehicleImage.offsetWidth
			if( visualizerVehicleWidth >= MAX_WIDTH ){
				setMaxWidth(MAX_WIDTH)
			}else{
				setMaxWidth(visualizerVehicleWidth)
			}
		}
	}


	useEffect(() => {
		onWindowResize()
		window.addEventListener('resize', onWindowResize)
		return () => {
			window.removeEventListener('resize', onWindowResize)
		}
	}, [])

	return (
		<VisualizerVehicle {...props}
			maxWidth={maxWidth}
			loaded={loaded}
			set_ref={ set_ref }
			responsive={true}
			onImageLoad={(imgStatus)=>{
				setLoaded({ loaded: imgStatus.loaded })
				onWindowResize()
			}}
		/>
	)
}
export default VisualizerVehicleResponsive
