import React, { Component } from 'react'

import { WheelResult } from './wheel_result'

export class WheelResultGrid extends Component{
	render(){
		if( this.props.wheels ){
			return (
				<div className="wheel-result-grid wrapper">
				{ this.props.wheels.map((wheel, index) => {
					return (
						<WheelResult
							wheel={wheel}
							key={`wheel-result-${wheel.item_num}`}
							onCheckout={this.props.onCheckout}
							onViewDetails={ this.props.select_wheel }
							viewed={ this.props.viewed[ wheel.model + '|' + wheel.finish ]}
						/>
					)
				})}
				</div>
			)
		}else{
			return (
				<div className="wheel-result-grid wrapper">

				</div>
			)
		}
	}
}

export default WheelResultGrid
