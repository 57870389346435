import Promise from 'bluebird';
import _ from 'lodash';
import { Cookies, log, plural, proper } from 'velox-visualizer-tools';

import * as actions from './actions';
import { action_load_options } from './vehicle_select/action_creators';

export const COOKIE_SELECTED_VEHICLE = 'VELOX_SELECTED_VEHICLE'

// Create default actions
var AC = {} // ACTION CREATORS
// For each defined action, automatically generate default action creator functions
_.values(actions).forEach( action => {
	AC[action] = function(data){
		return {
			type: action,
			data: data
		}
	}
})

export var DEFAULTS = AC

export function initialize_api(API){
	return {
		type: actions.INITIALIZE_API,
		data: API
	}
}

export function get_series_info(){
	return function(dispatch, getState){
		const API = getState().API
		if( _.isEmpty(getState().series) ){
			API.Wheels.Get.Series().then( series => {
				dispatch(AC[actions.GET_SERIES_INFO](series))
			})
		}
	}
}

export function load_results(results){
	return function(dispatch, getState){
		if(!results){
			console.error('[LOAD RESULTS]\tNo results to load.', results)
		}else{
			var { vehicle, wheels } = results
			if( window.VELOX_DEBUG ){
				log.debug('LOAD RESULTS', results)
			}

			let selected_vehicle = getState().form_select.selected
			if( selected_vehicle.make && selected_vehicle.model && selected_vehicle.year && selected_vehicle.size ){
				let vehicle_cookie = _.pick(vehicle, ['id', 'make', 'model', 'year', 'size', 'diameter'])
				vehicle_cookie.size =  vehicle_cookie.size || vehicle_cookie.diameter
				Cookies.SelectedVehicleCookie.set(vehicle_cookie)
				dispatch(AC[actions.LOAD_RESULTS](results))
				dispatch(get_series_info())
			}
		}
	}
}

export function showCost(showCost){
	return (dispatch, getState) => dispatch(actions.SHOW_COST, showCost)
}

export function toggle_filter(filter_name, filter_value){
	return function(dispatch, getState){
		let current_filters = getState().results.filters_map
		let d = {}
		d[filter_name] = filter_value
		if( current_filters[filter_name] && current_filters[filter_name].length && current_filters[filter_name].includes(filter_value) ){
			dispatch(AC.RESULTS_FILTER_REMOVE(d))
		}else{
			dispatch(AC.RESULTS_FILTER_ADD(d))
		}
	}
}

export function restore_vehicle(vehicle){
	return function(dispatch, getState){
		const API = getState().API
		let fields = ['make', 'model', 'year', 'size', 'wheel']
		let promises = []
		if( vehicle.make && vehicle.model && vehicle.year && vehicle.size ){
			fields.forEach( target => {
				let get_next = proper(plural(target))
				promises.push(API.Vehicles.Get[ get_next ]( vehicle ))
			})
			return Promise.all(promises)
				.then( results => {
					let makes = results[0],
						models = results[1],
						years = results[2],
						sizes = results[3],
						wheels = results[4]

					dispatch(action_load_options('make', makes))
					dispatch(action_load_options('model', models))
					dispatch(action_load_options('year', years))
					dispatch(action_load_options('size', sizes))
					dispatch(AC[actions.RESTORE_VEHICLE_SELECTION](vehicle))
					dispatch(load_results(wheels))
				})
		}else{
			if( window.DEBUG ){
				console.error('[restore_vehicle] Vehicle invalid.', vehicle)
			}
		}
	}
}

export default AC
