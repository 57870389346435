import _ from 'lodash'

import initial_state from './initial_state'
import { LOAD_RESULTS, MODAL_TOGGLE, SELECT_WHEEL, RESULTS_FILTER_ADD, RESULTS_FILTER_REMOVE, RESULTS_FILTER_RESET, RESULTS_SORT, VEHICLE_SELECT_VALUE, SHOW_DETAILS, SHOW_COST  } from '../../actions'
import { lower, preload_image } from 'velox-visualizer-tools'
import { get_images } from '../../product360'
const responses = {}

responses[SHOW_COST] = (state, action) => {
	const new_state = {...state}
	new_state.showCost = action
}

responses[LOAD_RESULTS] = (state, action) => {
	let new_state = _.assign({}, state)
	/*
		The data returned is actually of the form { vehicle, wheels },
		but here we only care about the wheels.
	*/
	let { wheels } = action.data
	let wheels_changed = !_.isEqual(new_state.wheels, wheels)
	let new_wheels = wheels
	if( wheels_changed ){
		new_wheels = wheels.map(wheel => {
			wheel.has_360 = wheel.images && wheel.images.product360
			wheel.images.hd = wheel.images.large.replace('600', '1000')
			preload_image(wheel.images.side)
			preload_image(wheel.images.large)
			if( wheel.has_360 ){
				wheel.images.product360.images = get_images({
					num_images: wheel.images.product360.frames,
					url: wheel.images.product360.url,
					format: 'jpg',
					size: 600
				})
			}
			if( wheel.brand === 'REIGN 6061' ){
				wheel.images.video = {
					url: 'https://www.youtube.com/embed/K3Q57EtE-Uk',
					poster: 'https://veloxwheel.com/static/uploads/videos/poster/reign-forged-poster.png',
					thumbnail: 'https://cdn.veloxwheel.com/thumbnails/video-reign-6061-forged-thumbnail.jpg'
				}
			}else if( wheel.brand === '6061 V-FORGED'){
				wheel.images.video = {
					url: 'https://www.youtube.com/embed/RG7K43SpT3g',
					poster: 'https://veloxwheel.com/static/uploads/videos/poster/reign-forged-poster.png',
					thumbnail: 'https://cdn.veloxwheel.com/thumbnails/video-v6061-forged-thumbnail.jpg'
				}
			}else{
				wheel.images.video = null
			}
			return wheel
		})
	}
	return _.assign({}, new_state, {
		wheels: wheels,
		wheels_orig: wheels,
		filters: [],
		filters_map: {},
		sort: {},
		show_details: false,
		is_loaded: true,
		has_results: wheels && wheels.length
	})
}

responses[VEHICLE_SELECT_VALUE] = (state, action) => {
	if( action.data.field !== 'size' ){
		return _.assign({}, state, {
			is_loaded: false
		})
	}else{
		return state
	}
}

responses[ RESULTS_FILTER_ADD ] = (state, action) => {
	let data = action.data,
		new_filters = _.isArray(data) ? data : [ data ],
		filters = (state.filters && state.filters.length) ? state.filters : [],
		filters_map = state.filters_map ? state.filters_map : {}

	// Add new filter to filters array
	filters = filters.concat( new_filters )

	// Get only unique values in filters
	filters = _.uniq(filters, (filter) => {
		if( _.isObject(filter) ){
			return JSON.stringify(filter)
		}else{
			return String(filter)
		}
	})

	// Based on new filter array, create new filter hash/map object
	filters_map = _.reduce( filters, (result, filter) => {
		Object.keys(filter).forEach( key => {
			key = lower(key)
			if( !result[key] ){
				result[key] = []
			}
			result[key].push(filter[key])
		})
		return result
	}, {})

	return _.assign({}, state, {
		filters: filters,
		filters_map: filters_map,
		show_details: false
	})
}

responses[ RESULTS_FILTER_REMOVE ] = (state, action) => {
	let target_filter = action.data,
		filters = [].concat(state.filters),
		target_filter_field = lower(Object.keys(target_filter)[0]),
		target_filter_value = target_filter[target_filter_field]

	// Remove filter from the filters array
	let r = _.remove(filters, f => f[target_filter_field] && f[target_filter_field] == target_filter_value )

	// Remove filter from the filters map
	var filters_map = state.filters_map ? _.assign({}, state.filters_map) : {}
	_.remove(filters_map[target_filter_field], value => value == target_filter_value)

	return _.assign({}, state, {
		filters: filters,
		filters_map: filters_map
	})
}

responses[ RESULTS_FILTER_RESET ] = (state, action) => {
	return _.assign({}, state, {
		filters: [],
		filters_map: {},
		show_details: false
	})
}

responses[ RESULTS_SORT ] = ( state, action ) => {
	let sort = action.data
	return _.assign({}, state, { sort: sort })
}

responses[ SHOW_DETAILS ] = ( state, action ) => {
	let show = action.data
	return _.assign({}, state, {
		show_details: show
	})
}

responses[ MODAL_TOGGLE ] = ( state, action ) => {
	let { modal, open } = action.data
	let new_state = _.assign({}, state)
	new_state.is_modal_open[modal] = open
	return new_state
}

responses[ SELECT_WHEEL ] = ( state, action ) => {
	return _.assign({}, state, {
		show_details: true
	})
}

export function reducer(state=initial_state, action){
	if( responses[action.type] ){
		return responses[action.type](state, action)
	}else{
		return state
	}
}

export default reducer
