import _ from "lodash";
import React from "react";

import { useImage360 } from "./useImage360";
import { Image360Loading, Image360Toolbar } from "./Image360ToolbarLoading";
import { Image360ErrorBoundary } from "./Image360ErrorBoundary";

import "./image360.scss";

export interface Image360Props {
  frames: number;
  url: string;
  images: string[];
  direction?: number;
  cycle?: boolean;
  cycles: 1;
  start: number;
  autoplay: boolean;
  format?: string;
  scroll?: boolean;
  interval?: number;
  visible: boolean;
  onRotate: () => void;
  zoom: any;
}

export function Image360(props: Image360Props) {
  const {
    images,
    circlrElement,
    imageWrapperRef,
    imageRefs,
    loadingPercent,
    classNames,
    onImageLoad,
  } = useImage360(props);

  return (
    <div className={classNames}>
      <Image360Loading percent={loadingPercent} />
      <div
        className="image-360-viewer-images image-wrapper"
        ref={imageWrapperRef}
      >
        {images &&
          images.map((url, i) => {
            return (
              <img
                src={url}
                data-index={i}
                key={url}
                //className={ currentImage == i + 1 ? "is-visible" : "is-not-visible"}
                onLoad={(e) => onImageLoad(url)}
                ref={imageRefs[i]}
                draggable="false"
              />
            );
          })}
      </div>
      <Image360Toolbar
        rotateLeft={() => circlrElement && circlrElement.prev()}
        rotateRight={() => circlrElement && circlrElement.next()}
      />
    </div>
  );
}

export function Image360WithErrorHandling(props: Image360Props) {
  return (
    <Image360ErrorBoundary>
      <Image360 {...props} />
    </Image360ErrorBoundary>
  );
}

export default Image360WithErrorHandling;
