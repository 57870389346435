import React, { useEffect, useState } from 'react';

import { ImageIfExistsProps } from './types';

/** Smart image component that checks if an image exists and displays the fallback otherwise.
 *
 */

export const ImageIfExists = React.forwardRef(function(props:ImageIfExistsProps, ref ){
	const { fallback, src, alt, onLoad, onError } = props
	const [exists, setExists] = useState(false)
	const [loaded, setLoaded] = useState(false)
	
	function onImgError(e){
		console.error(`[IMAGE] ${src} does not exist`)
		setExists(false)
		setLoaded(true)
		if( onError ){ onError({ loaded: true, exists: false }) }
	}

	function onImgLoad(){
		setExists(true)
		setLoaded(true)
		if( onLoad ){ onLoad({ loaded: true, exists: true }) }
	}
	
	// set image props
	const imageProps = { 
		...props,
		onError: onImgError,
		onLoad: onImgLoad,
	}
	delete imageProps.fallback 
	
	const found = exists,
		not_loaded = !loaded,
		not_found = !exists

	let FallbackImage = null

	if( not_loaded || (loaded && not_found) ){
		// Image is not loaded yet, or it's loaded and not found.
		// Show loading fallback if provided, otherwise use empty div
		if( fallback ) {
			if( typeof fallback === 'string' ){
				imageProps.src = fallback
				FallbackImage = <img {...imageProps} alt={alt || ""} />
			}else{
				FallbackImage = <React.Fragment>{fallback}</React.Fragment>
			}
		}else{
			FallbackImage = <div />
		}
	}

	return (
		<div className="image-wrapper image-if-exists">
			<img {...imageProps} alt={alt || ""} />
			<div className="fallback">{FallbackImage}</div>
		</div>
	)
	
})

export function checkImage(src){
	return new Promise((resolve, reject) => {
		let img = new Image()
		img.onload = e => resolve(e)
		img.onerror = e => reject(e)
		img.src = src
	})
}

export default ImageIfExists
