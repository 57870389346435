import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import tinycolor from 'tinycolor2'

import VehicleColorSwatch from './vehicle_color_swatch'
import { Dropdown, ColorWheel } from '../../../common'

import './vehicle_colors.scss'

export class VehicleColorOptionsDropdown extends Component{
	className(){
		let component_classes = ['vehicle-color-options', 'vehicle-color-options-dropdown']
		if( this.props.className ){ component_classes.push(this.props.className) }
		return component_classes.join(' ')
	}
	render(){
		let { colors, current_color } = this.props
		if( colors ){
			return (
				<div className={this.className()}>
					<div className='vxviz-label'>Vehicle Color</div>
					<Dropdown
						data={ colors }
						valueField="name"
						defaultValue={ current_color || (colors[0] ? colors[0].name : 'Colors') }
						value={ current_color }
						valueComponent={ VehicleColorSwatch }
						itemComponent={ VehicleColorSwatch }
						className="colors-dropdown"
						onChange={ this.props.select_color }
					/>
				</div>
			)
		}else{
			return (<div className="vehicle-color-options"></div>)
		}
	}
}

export class VehicleColorOptions extends Component{
	/*
	static propTypes = {
		colors: PropTypes.array.isRequired,
		onSelectColor: PropTypes.func,
		current_color: PropTypes.shape({
			name: PropTypes.string,
			rgb1: PropTypes.string,
			code: PropTypes.string
		})
	}
	*/
	state = {
		open: false
	}
	toggle = e => this.setState({ open: !this.state.open })
	select_color = (color) => (e => {
		this.props.onSelectColor(color)
		this.setState({ open: false })
	})
	render(){
		let { colors, current_color, onSelectColor } = this.props
		const colorRGB = {}
		const uniqueColors = colors.filter(color => {
			const rgb = color.rgb1 + color.rgb2
			if( colorRGB[rgb] ){
				return false
			}else{
				colorRGB[rgb] = true 
				return true
			}
		})
		return (
			<div className={ "vehicle-color-options" + (this.state.open ? ' is-open' : ' is-closed')}>
				<div className="vehicle-color-options-button btn" onClick={ this.toggle }>
					<img className="icon-color-palette" src="https://cdn.veloxwheels.com/ui/color-palette.png" alt="" />
					<div className="label">
						<span className="hide-mobile">Change</span> Color
					</div>
				</div>
				<ul className={"vehicle-color-options-list" + (this.state.open ? ' is-open' : ' is-closed')}>
					{
						uniqueColors.map( color => {
							return (
								<li
									onClick={ this.select_color(color) }
									className={ "vehicle-color-options-list-item" + ((current_color && current_color.name === color.name) ? " is-selected" : "")}
									key={"color-" + color.id}
								>
									<VehicleColorSwatch textValue={false} item={color} onClick={ this.select_color(color) } />
								</li>
							)
						})
					}
				</ul>
			</div>
		)
	}
}

export default VehicleColorOptions
