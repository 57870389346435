import React, { ReactNode } from 'react';

export class Image360ErrorBoundary extends React.Component<{ children: ReactNode; }, { hasError: boolean; }> {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}
	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}
	componentDidCatch(error, info) {
		console.error(error, info);
	}
	render() {
		const { hasError } = this.state;
		return (
			hasError ? (
				<div className={'image-360-viewer has-error'}>
					<h3>Wheel 360 Error</h3>
					<p>The product 360 view could not be loaded. Please try reloading the page.</p>
				</div>
			) : (
				this.props.children
			)
		);
	}
}
