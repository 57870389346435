import React, { Component, useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import { usePopper } from 'react-popper';

import { ShoppingCart, ShowHideIcon } from './icons'
import { upper } from 'velox-visualizer-tools'
import Clipboard from 'clipboard/dist/clipboard'

import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	PinterestShareButton,
	VKShareButton,
	FacebookIcon,
	TwitterIcon,
	LinkedinIcon,
	PinterestIcon
} from "react-share";
import { CopyIcon } from '../../../icons';

export class AddToCart extends Component{
	render(){
		return (
			<button className={ 'btn green btn-purchase ' + (this.props.className || '' ) } onClick={ this.props.onClick }>
				<ShoppingCart />
				Add to cart
			</button>
		)
	}
}

const SOCIAL_SHARE_BUTTON_SIZE = 24
export const FacebookShare = (props) => (
	<FacebookShareButton url={props.url} title={props.title} className="btn-social btn-social-facebook">
		<FacebookIcon size={SOCIAL_SHARE_BUTTON_SIZE} round={props.round} />
	</FacebookShareButton>
)

export const TwitterShare = (props) => (
	<TwitterShareButton url={props.url} title={props.title} className="btn-social btn-social-twitter">
		<TwitterIcon size={SOCIAL_SHARE_BUTTON_SIZE} round={props.round} />
	</TwitterShareButton>
)


export const PinterestShare = (props) => (
	<PinterestShareButton url={props.url} media={props.image} windowWidth={1000} windowHeight={730} className="btn-social btn-social-pinterest">
		<PinterestIcon size={SOCIAL_SHARE_BUTTON_SIZE} round={props.round} />
	</PinterestShareButton>
)

export const SocialShareButtonsMap = {
	"FACEBOOK": FacebookShare,
	"TWITTER": TwitterShare,
	"PINTEREST": PinterestShare
}

export const SocialShareButtons = (props) => {
	var { networks, title, url, image, label, round } = props
	if( !networks ){
		networks = Object.keys(SocialShareButtonsMap)
	}else{
		networks = _.filter(networks, n => Object.keys(SocialShareButtonsMap).indexOf(upper(n)) >= 0)
	}
	return (
		<ul className="social-share-buttons">
			{ !!label && <li className="label" key={'social-btn-label'}>{label}</li> }
			{
				networks.map((network, i)=> {
					let SocialButton = SocialShareButtonsMap[upper(network)]
					return (
						<li key={"social-btn-"+i}>
							<SocialButton
								url={url}
								title={title}
								image={image}
								round={round}
							/>
						</li>
					)
				})
			}
		</ul>
	)
}

export const AddToClipboardButton = ({ value, title }) => {
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const [arrowElement, setArrowElement] = useState(null);
	const [showPopover, setShowPopover] = useState(false)
	const clipboardElement = useRef(null)

	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
		placement: "top"
	});

	useEffect(() => {
		if( referenceElement ){
			clipboardElement.current = new Clipboard(referenceElement)
			clipboardElement.current.on('success', (e)=>{
				setShowPopover(true)
		
				setTimeout(()=>{
					setShowPopover(false)
				}, 3000)
			})
		}
	}, [referenceElement])
	
	return (
		<>
			<button 
				className="add-to-clipboard-button" 
				data-clipboard-text={value} 
				title={title} 
				ref={setReferenceElement}
			>
				<CopyIcon />
			</button>
			<div className={`add-to-clipboard-button-popover ${showPopover ? 'is-visible' : ''}`} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
				Copied
				<div className="popper-arrow" ref={setArrowElement} style={styles.arrow} />
			</div>
		</>
	)
}


export const ShowCostButton = ({ showCost, setCostVisibility }) => (
	<button 
		className={"show-cost-button " + (showCost ? 'hide' : 'show') + '-cost'} 
		onClick={() => setCostVisibility(!showCost)}
	>
		<ShowHideIcon show={!showCost} />
		{ showCost ? 'Hide Cost' : 'Show Cost' }
	</button>
)