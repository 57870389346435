//----------------- IMPORTS -------------------//
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Cookies, get_font_stylesheet, register_service_worker } from 'velox-visualizer-tools'
import * as Tools from 'velox-visualizer-tools'

import Pkg from '../../../package.json'
import api from './core/api'
import store from './core/store'
import * as action_creators from './core/action_creators'
import * as actions from './core/actions'
import { ConnectedVisualizer } from './core/visualizer'
import { VehicleSelectionForm, ConnectedVehicleSelectionForm } from './core/vehicle_select'
import { VeloxVisualizerApp } from './VeloxVisualizerApp'
import { DEFAULT_CONFIG, VeloxVisualizerContext } from './core/context'

//----------------- EXPORTS -------------------//
// Constants/Utilities
export const VERSION = Pkg.version
export const COOKIES = Tools.Cookies
export const ACTION_CREATORS = action_creators
export const ACTIONS = actions
export var STORE = store()
export var API = api()

// Components
export * from './core/visualizer'
export * from './core/visualizer/vehicle/visualizer_vehicle'
export * from './core/visualizer/vehicle/vehicle_wheel'
export * from './core/visualizer/vehicle/vehicle_image'
export * from './core/visualizer/vehicle/vehicle_color_options'
export { VehicleSelectionForm, ConnectedVehicleSelectionForm } from './core/vehicle_select'
export * from './VeloxVisualizerApp'

// Methods
export function initialize_api(token, url){
	API = api(token, url)
	return STORE.dispatch( action_creators.initialize_api(API) )
}

export function set_vehicle(vehicle){
	STORE.dispatch( action_creators.restore_vehicle(vehicle || {}) )
	if( !vehicle ){
		COOKIES.SelectedVehicleCookie.delete()
		COOKIES.SelectedWheelCookie.delete()
		COOKIES.SelectedQtyCookie.delete()
	}
}

export function initialize(settings={}){

	settings = settings || {}
	const API_TOKEN = window.VELOX_API_TOKEN = settings.token

	var V = {
		STORE: STORE,
		API: API,
		API_TOKEN: API_TOKEN
	}
	V.initialize_api = (token=settings.token) => {
		V.API_TOKEN = token
		V.API = api(token, settings.api_url)
		initialize_api(token)
		return V
	}
	V.render_visualizer = (dom_element, config) => {
		const context = { ...DEFAULT_CONFIG, ...config }
		if(config.vehicle){
			set_vehicle(config.vehicle)
		}
		ReactDOM.render(
			<VeloxVisualizerContext.Provider value={context}>
				<Provider store={STORE}>
					<ConnectedVisualizer {...config} />
				</Provider>
			</VeloxVisualizerContext.Provider>,
			dom_element
		)
		return V
	}
	V.render_vehicle_selection_form = (dom_element, config) => {
		if(config.vehicle){
			set_vehicle(config.vehicle)
		}
		ReactDOM.render(
			<VeloxVisualizerContext.Provider value={config}>
				<Provider store={STORE}>
					<ConnectedVehicleSelectionForm {...config} />
				</Provider>
			</VeloxVisualizerContext.Provider>,
			dom_element
		)
		return V
	}
	V.initialize_api(API_TOKEN)

	return V
}

export default initialize
